import React from 'react'
import { FaUser } from 'react-icons/fa'
import { convertDateToDdMmYyyy, convertToAmPm } from '../../Helper/calculations'
import styles from '../Bookings/CSBookingDetails.module.css'

const CSBookingDetails = props => {
    const item = props.item
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.bookingId} title={`Booking Id is : ${item?.bookingDetails["bookingId"]}`}>
                #{item.bookingDetails["ID"]}
            </div>
            <div className={styles.clientName}>
                <div className={styles.userIcon}>
                <FaUser size={18} />
                </div>
                {item.bookingDetails['clientName'] || `NA`}
            </div>
            <div className={styles.createdOn}>
                {convertDateToDdMmYyyy(item.bookingDetails["createdOn"]?.substring(0, 10))}
            </div>
            <div className={styles.createdOn}>
                {convertToAmPm(item?.bookingDetails["createdOn"]?.substring(11, 19))}
            </div>
            <div className={styles.total}>
                {`₹${item?.bookingDetails?.amountToBeCollected ?? "-"}`}
            </div>
            <div className={styles.currentStatus}>
                {item?.bookingDetails["currentStatus"]==="BOOKING_COMPLETED" && <span className={styles.success} >Completed</span>}
                {item?.bookingDetails["currentStatus"]==="BOOKING_INITIATED" && <span className={styles.initiated} >Initiated</span>}
            </div>
            <div className={styles.servicesInCart}>

                {item?.allServices?.length>0 ? `${item?.allServices?.length} item(s) billed` : `No items were billed`}
            </div>
        </div>
    )
}

export default CSBookingDetails