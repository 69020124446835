import React, { useContext, useEffect, useState } from 'react'
import styles from '../../Styles/Components/Services/EachServiceList.module.css'
import { BASE_URL_API } from '../../References/URLs';
import EachServicesCategory from './EachServicesCategory';
import EachServiceAddOns from './EachServiceAddOns';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { toast, ToastContainer } from 'react-toastify';


const EachServiceList = (props) => {
    const { activeSection, sections } = props
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();


    const [isFetchingCategories, setIsFetchingCategories] = useState(false)

    const [categoryLabel, setCategoryLabel] = useState('')
    const [creatingNewCategory, setCreatingNewCategory] = useState(false)

    const [categories, setCategories] = useState([])

    const [selectedService, setSelectedService] = useState(null)

    const selectServiceHandler = (k) => {
        setSelectedService(k);
    }



    const getServiceCategories = async () => {
        setIsFetchingCategories(true)
        const getServiceCategoriesResponse = await fetch(BASE_URL_API + "getServicesCategories",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sectionIdentifier: activeSection
                })
            });

        if (!getServiceCategoriesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getServiceCategoriesRespo = await getServiceCategoriesResponse.json();
            if (getServiceCategoriesRespo.status === "success") {
                setCategories(getServiceCategoriesRespo.response);
            } else {
                if (getServiceCategoriesRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setCategories([])
                }
                console.log(getServiceCategoriesRespo)
            }
        }
        setIsFetchingCategories(false)
    }

    useEffect(() => {
        getServiceCategories();
    }, [activeSection])


    const createNewServiceCategory = async () => {
        console.log(activeSection, categoryLabel, activeStore?.storeIdentifier)
        setCreatingNewCategory(true)
        const createNewServiceCategoryResponse = await fetch(BASE_URL_API + "createNewServiceCategory",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sectionIdentifier: activeSection,
                    categoryLabel: categoryLabel,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!createNewServiceCategoryResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const createNewServiceCategoryRespo = await createNewServiceCategoryResponse.json()
            if (createNewServiceCategoryRespo.status === "success") {
                setCategoryLabel('')
                toast('Successfully added the category')
            } else {
                if (createNewServiceCategoryRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    if (createNewServiceCategoryRespo?.message === "invalid identifier served") {
                        toast(`Please select a category!`)
                    }
                }
                console.log(createNewServiceCategoryRespo)
            }
        }
        setCreatingNewCategory(false)
    }


    if (activeStore === null) {
        return (
            <div className={styles.mainContainer}>
                Please select a store first!
            </div>
        )
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.mainContent}>
                <div className={styles.serviceListContainer}>
                    {/* <ToastContainer /> */}
                    <div>
                        {isFetchingCategories === false ?
                            <div>
                                {categories.length > 0 ?
                                    <div>
                                        <div>
                                            {categories.map((item, index) => (
                                                <EachServicesCategory
                                                    item={item}
                                                    key={index}
                                                    selectServiceHandler={selectServiceHandler}
                                                    activeSection={activeSection}
                                                    sections={sections}
                                                    onDeletion={getServiceCategories}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.noCategoryWrapper}>
                                        <div className={styles.notFound}>
                                            No categories found for this section!
                                        </div>
                                    </div>
                                }
                                <div className={styles.addNewCategory}>
                                    <div className={styles.enterCategoryInput}>
                                        <input placeholder="Enter category name..."
                                            onChange={(e) => setCategoryLabel(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.enterCategoryBtn}>
                                        {creatingNewCategory === false ?
                                            <button className={styles.createNewBtn}
                                                onClick={() => createNewServiceCategory()}
                                            >
                                                Create New
                                            </button>
                                            :
                                            <button className={styles.createNewBtn}
                                            >
                                                Creating...
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                Fetching categories...
                            </div>
                        }
                    </div>
                </div>
                <div className={styles.addOnContainer}>
                    <EachServiceAddOns selectedService={selectedService} />
                </div>
            </div>
        </div>
    )
}

export default EachServiceList