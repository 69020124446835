import React, { useContext, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { FaRecycle } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from './BookingActions.module.css'
import DeleteBookingConfirmation from './DeleteBookingConfirmation'

const BookingActions = (props) => {
    const { isOpen, toggleBookingOptions, bookingIdentifier, bookingDetails } = props
    const authCtx = useContext(AuthContext)

    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)
    const toggleDeleteConfirmation = () => setIsDeleteConfirmationOpen(false)
    const [bookingDate, setBookingDate] = useState(bookingDetails?.payStatusLastUpdatedAt?.substring(0, 10))


    window.onclick = function (event) {
        if (event.target.id == "bookingOptionsContainer") {
            toggleBookingOptions()
        }
    }

    const updateBookingDateHandler = async () => {
        console.log(bookingDate, bookingIdentifier)
        const updateBookingDateResponse = await fetch(BASE_URL_API + "updateBookingDate",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingDate: bookingDate,
                    bookingIdentifier: bookingIdentifier
                })
            });

        if (!updateBookingDateResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateBookingDateRespo = await updateBookingDateResponse.json()
            if (updateBookingDateRespo.status === "success") {
                toast("Booking date successfully updated!")
                toggleBookingOptions();
            } else {
                if (updateBookingDateRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(updateBookingDateRespo)
        }
    }


    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                {isDeleteConfirmationOpen && <DeleteBookingConfirmation
                    isOpen={isDeleteConfirmationOpen}
                    toggleOpen={setIsDeleteConfirmationOpen}
                    bookingIdentifier={bookingIdentifier}
                    bookingDetails={bookingDetails}
                />}
                <div className={styles.mainContainer} id="bookingOptionsContainer">
                    <div className={styles.closeBtn}>
                        <button onClick={() => toggleBookingOptions()} > 
                            <MdClose size={26} />
                        </button>
                    </div>
                    {bookingDetails?.currentStatus !== "BOOKING_DISCARDED" ?
                        <div className={styles.mainContent}>
                            <div className={styles.mainHeader}>
                                Update Client Visit / Booking
                            </div>
                            <div className={styles.mainBody}>
                                <div className={styles.actionsWrapper}>

                                    <div className={styles.eachActionWrapper}>
                                        <div className={styles.changeBookingDate}>
                                            <div className={styles.dateInputTxt}>
                                                Change Booking Date
                                            </div>
                                            <div className={styles.dateInputWrapper}>
                                                <input type="date" value={bookingDate}
                                                    onChange={(e) => setBookingDate(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.updateDate}>
                                            <button onClick={() => updateBookingDateHandler()} >Update</button>
                                        </div>
                                    </div>

                                    <div className={styles.eachActionWrapper}>
                                        <div className={styles.discardBooking} onClick={() => setIsDeleteConfirmationOpen(true)} >
                                            <div className={styles.actionIconWrapper}>
                                                <AiOutlineDelete size={20} />
                                            </div>
                                            <div>
                                                Delete Booking
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className={styles.mainFooter}>

                            </div>
                        </div>
                        :
                        <div className={styles.mainContent}>
                            <div className={styles.mainHeader}>

                            </div>
                            <div className={styles.bookingDeleted}>
                                This Booking has been deleted!
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default BookingActions