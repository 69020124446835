import React, { useContext, useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import AddNewExpense from '../../Components/Expenses/AddNewExpense'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './ExpensesHome.module.css'
import { Link } from 'react-router-dom'
import { convertDateToDdMmYyyy, convertToAmPm } from '../../Helper/calculations'
import DateRangeSelector from '../../Components/UI/DateRangeSelector'
import { BiCalendar } from 'react-icons/bi'

const ExpensesHome = () => {
    const [isNewOpen, setIsNewOpen] = useState(false)
    const toggleNewOpen = () => {
        setIsNewOpen(false)
    }
    const [allExpenses, setAllExpenses] = useState([])

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }

    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const onNewAddon = () => {
        toast.success(`New expense added!`)
        setIsNewOpen(false)
        getExpenses()
    }

    const getExpenses = async () => {
        const getExpensesResponse = await fetch(BASE_URL_API + "getExpenses",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    startDate: dates?.startDate,
                    endDate: dates?.endDate
                })
            });

        if (!getExpensesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getExpensesRespo = await getExpensesResponse.json()
            if (getExpensesRespo.status === "success") {
                setAllExpenses(getExpensesRespo?.response)
            } else {
                if (getExpensesRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllExpenses([])
                }
            }
            console.log(getExpensesRespo)
        }
    }

    useEffect(() => {
        getExpenses()
    }, [activeStore?.storeIdentifier, dates?.startDate, dates?.endDate])

    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                {isNewOpen && <AddNewExpense isOpen={isNewOpen} onClose={toggleNewOpen} onSuccess={onNewAddon} />}
                <div className={styles.mainHeader}>
                    <div>All Expenses</div>
                    <div className={styles.optionWrapper}>
                        <div className={styles.dateWrapper}>
                            <div className={styles.dateRangeSelectorWrapperBtn}
                                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                            >
                                {dates.startDate === dates.endDate ?
                                    <div>
                                        {dates.startDate} &nbsp;
                                    </div>
                                    :
                                    <div>
                                        From {dates.startDate} to {dates.endDate} &nbsp;
                                    </div>
                                }

                                <BiCalendar />
                            </div>
                            <DateRangeSelector
                                isOpen={isDatePickerOpen}
                                closeModal={closeModal}
                                changeDateHandler={changeDateHandler}
                            />
                        </div>
                        <Link to={`/expenses-report`} className={styles.collectionBtn} >Expenses Report</Link>
                        <button className={styles.AddNewExpenseBtn} onClick={() => setIsNewOpen(true)} >Add New Expenses</button>
                    </div>
                </div>

                <div className={styles.mainContainer}>
                    <div className={styles.mainTableWrapper}>
                        <div className={styles.mainTableHeader} >
                            <div className={styles.sn} >#.</div>
                            <div className={styles.category}>Category</div>
                            <div className={styles.subCategory}>Sub Category</div>
                            <div className={styles.expenseDetails}>Expense Details</div>
                            <div className={styles.price}>Amount (₹)</div>
                            <div className={styles.date}>Date</div>
                            <div className={styles.time}>Time</div>
                        </div>
                        <div className={styles.itemsWrapper}>
                            {allExpenses?.map((item, index) => (
                                <div className={styles.eachItemWrapper} key={index} >
                                    <div className={styles.sn} >{index + 1}.</div>
                                    <div className={styles.category}>{item?.category}</div>
                                    <div className={styles.subCategory}>{item?.subCategory}</div>
                                    <div className={styles.expenseDetails}>
                                        <div className={styles.expenseName}>
                                            {item?.expenseName}
                                        </div>
                                        <div className={styles.expenseNotes}>
                                            {item?.expenseNotes}
                                        </div>
                                    </div>
                                    <div className={styles.price}>₹{item?.amount}</div>
                                    <div className={styles.date}>{convertDateToDdMmYyyy(item?.createdOn?.substring(0, 10))}</div>
                                    <div className={styles.time}>{convertToAmPm(item?.createdOn?.substring(11, 19))}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExpensesHome