import React, { useContext, useEffect, useState } from 'react'
import ActiveStoreContext from '../../store/ActiveStoreContext';
import styles from './InvoiceBrandLogo.module.css'

const InvoiceBrandLogo = (props) => {
    const { url } = props
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [base64, setBase64] = useState('');

    const convertImageToBase64 = async (imageUrl) => {
        try {
            const response = await fetch(`${imageUrl}`);
            const blob = await response.blob();

            const reader = new FileReader();
            reader.onloadend = () => {
                setBase64(reader.result); // The result is a base64 string
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error("Error converting image to base64:", error);
        }
    };

    useEffect(() => {
        // convertImageToBase64(activeStore?.invoiceLogo)
        convertImageToBase64(url)
    }, [url])
    


    return (
        <div className={styles.logoWrapper}>
            <img alt="" src={`${base64}`} />
        </div>
    )
}

export default InvoiceBrandLogo