import React, { useContext, useEffect, useState } from 'react'
import { BiPrinter } from 'react-icons/bi';
import { BsArrowDownLeft, BsArrowUpRight } from 'react-icons/bs';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { convertToAmPm, humanReadableTimePassed } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Users/AllLedgerTransactionsWrapper.module.css'
import CollectLedgerBalance from './CollectLedgerBalance';
import PrintCollectionReceipt from './PrintCollectionReceipt';

const AllLedgerTransactionsWrapper = props => {
    const authCtx = useContext(AuthContext);
    const clientStoreRelationIdentifier = props.clientStoreRelationIdentifier;
    const { clientDetails } = props

    const [allTransactions, setAllTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isAddingNewTransaction, setIsAddingNewTransaction] = useState(false)
    const [isPrintingOpen, setIsPrintingOpen] = useState(false)
    const [activeTransaction, setActiveTransaction] = useState(null)

    const getAllLedgerTransactions = async () => {
        setIsLoading(true)
        const getAllLedgerTransactionsResponse = await fetch(BASE_URL_API + "getAllLedgerTransactions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!getAllLedgerTransactionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllLedgerTransactionsRespo = await getAllLedgerTransactionsResponse.json();
            if (getAllLedgerTransactionsRespo.status === 'success') {
                setAllTransactions(getAllLedgerTransactionsRespo.response)
            } else {
                if (getAllLedgerTransactionsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setAllTransactions([])
                    console.log(getAllLedgerTransactionsRespo)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (clientStoreRelationIdentifier !== '') {
            getAllLedgerTransactions()
        }
    }, [clientStoreRelationIdentifier])

    const onSuccess = () => {
        toast(`Successfully updated!`)
        setIsAddingNewTransaction(false)
        getAllLedgerTransactions()
    }

    const handlePrintRequest = async (k) => {
        setActiveTransaction(k)
        setIsPrintingOpen(true)
    }

    return (
        <div className={styles.tableWrapper}>
            {isAddingNewTransaction === true && <CollectLedgerBalance
                isOpen={isAddingNewTransaction}
                onClose={() => setIsAddingNewTransaction(false)}
                clientStoreRelationIdentifier={clientStoreRelationIdentifier}
                onSuccess={onSuccess}
            />}
            {isPrintingOpen === true &&
                <PrintCollectionReceipt isOpen={isPrintingOpen} data={activeTransaction} clientData={clientDetails} onClose={() => setIsPrintingOpen(false)} />
            }
            <div className={styles.countTitle}>
                <div>
                    {isLoading === true ?
                        <div>
                            Collecting transactions...
                        </div>
                        :
                        <div>
                            {allTransactions.length} transactions available
                        </div>
                    }
                </div>
                <div className={styles.addNewTrans}>
                    <button onClick={() => setIsAddingNewTransaction(true)} >+ Add New Transaction</button>
                </div>
            </div>
            <div className={styles.transactionWrapper}>
                <div className={styles.eachRowWrapperHeader}>
                    <div className={styles.iconWrapperHolder}>
                        #
                    </div>
                    <div className={styles.dateWrapper}>
                        Date
                    </div>
                    <div className={styles.amount}>
                        Amount
                    </div>
                    <div className={styles.transactionType}>
                        Transaction Type
                    </div>
                    <div className={styles.currentBalance}>
                        Current Balance
                    </div>
                    <div className={styles.ago}>
                        Ago
                    </div>
                    <div className={styles.action}>
                        Action
                    </div>
                </div>
                <div className={styles.transactionsWrapper}>
                    {allTransactions.map((item, index) => (
                        <div key={index} className={styles.eachRowWrapper}>
                            <div className={styles.iconWrapperHolder}>
                                {item?.transactionType === "credit" ?
                                    <div className={styles.iconWrapper}>
                                        <BsArrowDownLeft size={20} color="#027FFF" />
                                    </div>
                                    :
                                    <div className={styles.iconWrapper}>
                                        <BsArrowUpRight size={20} color="red" />
                                    </div>
                                }
                            </div>
                            <div className={styles.dateWrapper}>
                                {/* <div className={styles.ago}>
                                {humanReadableTimePassed(item?.transactionDate)}
                            </div> */}
                                <div className={styles.transactionDate}>
                                    {item?.transactionDate?.substring(0, 10)}
                                </div>
                                <div className={styles.transactionTime}>
                                    At {convertToAmPm(item?.transactionDate?.substring(10, 18))}
                                </div>
                            </div>
                            <div className={styles.amount}>
                                ₹{item.amount}
                            </div>
                            <div className={styles.transactionType}>
                                {item.transactionType}
                            </div>
                            <div className={styles.currentBalance}>
                                {/* ₹{item.currentBalance} */}
                                {item.currentBalance < 0 ? `-₹${Math.abs(item.currentBalance)}` : `₹${item.currentBalance}`}
                            </div>
                            <div className={styles.ago}>
                                <div className={styles.remarks}>
                                    {item?.transactionRemarks}
                                </div>
                                <div>
                                    {humanReadableTimePassed(item?.transactionDate)}
                                </div>
                            </div>
                            <div className={styles.action}>
                                {item?.transactionType === "credit" &&
                                    <div className={styles.print} onClick={() => handlePrintRequest(item)}>
                                        <BiPrinter title="Print Receipt" size={24} color="#a5a5a5" />
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                    <div className={styles.thatsAll}>
                        That's all!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllLedgerTransactionsWrapper