import React, { useState, useEffect, useContext } from 'react'
import styles from './Overview.module.css'
import { Link } from 'react-router-dom'
import { IoMdWallet, IoIosFlash } from "react-icons/io";
import { MdOutlineEventAvailable, MdCheckBoxOutlineBlank, MdCheckBox, MdDeleteOutline } from "react-icons/md";
import { IoFootsteps } from "react-icons/io5";
import { IoEllipsisVertical } from "react-icons/io5";
import { AreaChart, BarChart, PieChart, Pie, Legend, Bar, linearGradient, XAxis, YAxis, CartesianGrid, Tooltip, Area } from 'recharts'
import AuthContext from '../../store/auth-context';
import { toast, ToastContainer } from 'react-toastify';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import DateRangeSelector from '../../Components/UI/DateRangeSelector';
import { BiCalendar } from 'react-icons/bi';
import { Chart } from "react-google-charts";

const Overview = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [overviewStats, setOverviewStats] = useState(null)
    const [servicesStats, setServicesStats] = useState([])
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }



    const data = [
        {
            "name": "Page A",
            "uv": 1000,
            "pv": 1400,
            "amt": 1400
        },
        {
            "name": "Page B",
            "uv": 3000,
            "pv": 1398,
            "amt": 2210
        },
        {
            "name": "Page A",
            "uv": 4000,
            "pv": 2400,
            "amt": 2400
        },
        {
            "name": "Page C",
            "uv": 2000,
            "pv": 9800,
            "amt": 2290
        },
        {
            "name": "Page D",
            "uv": 2780,
            "pv": 3908,
            "amt": 2000
        },
        {
            "name": "Page E",
            "uv": 1890,
            "pv": 4800,
            "amt": 2181
        },
        {
            "name": "Page F",
            "uv": 2390,
            "pv": 3800,
            "amt": 2500
        },
        {
            "name": "Page G",
            "uv": 3490,
            "pv": 4300,
            "amt": 2100
        }
    ]

    const [menuWidth, setMenuWidth] = useState(document?.getElementById("leftMenuId")?.offsetWidth || 0);
    const [elemWidth, setElemWidth] = useState(200)

    const updateWidths = () => {
        const newMenuWidth = document?.getElementById("leftMenuId")?.offsetWidth || 0;
        const newElemWidth = document?.getElementById("chartAreaWrapper")?.offsetWidth || 0;

        setMenuWidth(newMenuWidth);
        setElemWidth(newElemWidth);
    };


    useEffect(() => {
        // Initially set widths
        updateWidths();

        // Add resize event listener
        window.addEventListener("resize", updateWidths);

        // Cleanup function to remove event listener on unmount
        return () => {
            window.removeEventListener("resize", updateWidths);
        };
    }, []);

    useEffect(() => {
        // Recalculate elemWidth if menuWidth changes
        setElemWidth(document?.getElementById("chartAreaWrapper")?.offsetWidth || 0);
    }, [menuWidth]);

    const [allTasks, setAllTasks] = useState([
        { id: '1', isDone: true, task: 'Content' }
    ])

    const [task, setTask] = useState('')
    const [isAddingTask, setIsAddingTask] = useState(false)
    const [isGettingTask, setIsGettingTask] = useState(false)
    const [togglingTaskStatus, setTogglingTaskStatus] = useState(false)

    const addTask = async () => {
        setIsAddingTask(true)
        const addTaskResponse = await fetch(BASE_URL_API + "addTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    authIdentifier: authCtx?.authIdentifier,
                    task: task
                })
            });

        if (!addTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addTaskRespo = await addTaskResponse.json()
            if (addTaskRespo?.status === "success") {
                toast('Successfully created a task!')
                getTask()
                setTask('')
            } else {
                if (addTaskRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    toast("Could not add a task!")
                }
            }
            console.log(addTaskRespo)
        }
        setIsAddingTask(false)
    }

    const getTask = async () => {
        setIsGettingTask(true)
        const getTaskResponse = await fetch(BASE_URL_API + "getTasks",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    authIdentifier: authCtx?.authIdentifier
                })
            });

        if (!getTaskResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getTaskRespo = await getTaskResponse.json()
            if (getTaskRespo?.status === "success") {
                setAllTasks(getTaskRespo?.response)
            } else {
                if (getTaskRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllTasks([])
                    console.log(getTaskRespo)
                }
            }
        }
        setIsGettingTask(false)
    }

    useEffect(() => {
        getTask()
    }, [])

    const toggleTaskStatus = async (k) => {
        setTogglingTaskStatus(true)
        const toggleTaskStatusResponse = await fetch(BASE_URL_API + "toggleTaskStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    taskIdentifier: k
                })
            });

        if (!toggleTaskStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const toggleTaskStatusRespo = await toggleTaskStatusResponse.json()
            if (toggleTaskStatusRespo?.status === "success") {
                getTask()
            } else {
                if (toggleTaskStatusRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
                console.log(toggleTaskStatusRespo)
            }
        }
        setTogglingTaskStatus(false)
    }

    const deleteTaskHandler = async (k) => {
        setTogglingTaskStatus(true)
        const deleteTaskStatusResponse = await fetch(BASE_URL_API + "deleteTask",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    taskIdentifier: k
                })
            });

        if (!deleteTaskStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteTaskStatusRespo = await deleteTaskStatusResponse.json()
            if (deleteTaskStatusRespo?.status === "success") {
                getTask()
                toast(`Task deleted successfully`)
            } else {
                if (deleteTaskStatusRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
                console.log(deleteTaskStatusRespo)
            }
        }
        setTogglingTaskStatus(false)
    }


    const getCollectionSummary = async () => {
        const getCollectionSummaryResponse = await fetch(BASE_URL_API + "getCollectionSummary",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getCollectionSummaryResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCollectionSummaryRespo = await getCollectionSummaryResponse.json()
            if (getCollectionSummaryRespo.status === "success") {
                setOverviewStats(getCollectionSummaryRespo?.response)
                setServicesStats(getCollectionSummaryRespo?.response?.servicesStats)
            } else {
                if (getCollectionSummaryRespo.message === 'tokenExpired') {
                    authCtx?.logout()
                } else {
                    setOverviewStats(null)
                    console.log(getCollectionSummaryRespo)
                }
            }
        }
    }

    useEffect(() => {
        getCollectionSummary()
    }, [activeStore?.storeIdentifier, dates])


    const options = {
        title: "My Daily Activities",
    };

    function convertServicesToPlot(services) {
        const servicesStatsToPlot = [
            ["Service", "Total Count"] // Header for the chart
        ];

        services.forEach(service => {
            servicesStatsToPlot.push([service.serviceName, service.totalCount]);
        });

        return servicesStatsToPlot;
    }


    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        Please select a store!
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />

            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.upperHeader}>
                        <div>
                            <div className={styles.hiname}>
                                {activeStore?.storeName} CRM
                            </div>
                            <div className={styles.proName}>
                                {activeStore?.storeAddress}
                            </div>
                        </div>
                        <div >
                            <Link to={`/add-clients`} className={styles.addClient} >+ Add Client</Link>
                        </div>
                    </div>

                    <div className={styles.dateWrapper}>
                        <div className={styles.dateRangeSelectorWrapperBtn}
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            {dates.startDate === dates.endDate ?
                                <div>
                                    {dates.startDate} &nbsp;
                                </div>
                                :
                                <div>
                                    From {dates.startDate} to {dates.endDate} &nbsp;
                                </div>
                            }

                            <BiCalendar />
                        </div>
                        <DateRangeSelector
                            isOpen={isDatePickerOpen}
                            closeModal={closeModal}
                            changeDateHandler={changeDateHandler}
                        />

                    </div>

                    <div className={styles.tilesWrapper}>
                        <div className={styles.eachTileWrapper}>
                            <div className={styles.tValueWrapper}>
                                <div className={styles.tvalue}>
                                ₹{parseFloat(overviewStats?.totalCollection || 0).toFixed(1)}
                                </div>
                                <div>
                                    <IoMdWallet size={20} color="#007FFF" />
                                </div>
                            </div>
                            <div className={styles.tCaption}>
                                Total Sales
                            </div>
                        </div>
                        <div className={styles.eachTileWrapper}>
                            <div className={styles.tValueWrapper}>
                                <div className={styles.tvalue}>
                                    {overviewStats?.totalTransactions}
                                </div>
                                <div>
                                    <MdOutlineEventAvailable size={20} color="#F9837C" />
                                </div>
                            </div>
                            <div className={styles.tCaption}>
                                Total Appointments
                            </div>
                        </div>
                        <div className={styles.eachTileWrapper}>
                            <div className={styles.tValueWrapper}>
                                <div className={styles.tvalue}>
                                    {overviewStats?.totalUserEnrollment}
                                </div>
                                <div>
                                    <IoIosFlash size={20} color="#70B6C1" />
                                </div>
                            </div>
                            <div className={styles.tCaption}>
                                New Customer Enrolled
                            </div>
                        </div>
                        <div className={styles.eachTileWrapper}>
                            <div className={styles.tValueWrapper}>
                                <div className={styles.tvalue}>
                                    -
                                </div>
                                <div>
                                    <IoFootsteps size={20} color="#F3CC5D" />
                                </div>
                            </div>
                            <div className={styles.tCaption}>
                                Visitor's Conversion Rate
                            </div>
                        </div>

                    </div>

                    <div className={styles.cardsWrapper}>
                        <div className={styles.eachCardWrapperBig}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Services Breakup
                                </div>
                                <div>
                                    <IoEllipsisVertical color={"#898989"} />
                                </div>
                            </div>

                            <div>
                                <Chart
                                    chartType="PieChart"
                                    data={convertServicesToPlot(servicesStats)}
                                    options={options}
                                    width={"100%"}
                                    height={"300px"}
                                />
                            </div>
                        </div>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Income Amount
                                </div>
                                <div>
                                    <IoEllipsisVertical color={"#898989"} />
                                </div>
                            </div>
                            <div>
                                <BarChart width={elemWidth} height={250} data={data}>
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    {/* <XAxis dataKey="name" /> */}
                                    {/* <YAxis /> */}
                                    {/* <Tooltip /> */}
                                    {/* <Legend /> */}
                                    <Bar dataKey="pv" fill="#007FFF" barSize={8} />
                                </BarChart>
                            </div>
                        </div>
                        <div className={styles.eachCardWrapper}>
                            <div className={styles.cardHeader}>
                                <div className={styles.cardTitle}>
                                    Revenue
                                </div>
                                <div>
                                    <IoEllipsisVertical color={"#898989"} />
                                </div>
                            </div>

                            <div id="chartAreaWrapper">
                                <AreaChart width={elemWidth} height={250} data={data}
                                    margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#027FFF" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#027FFF" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    {/* <XAxis dataKey="name" /> */}
                                    {/* <YAxis /> */}
                                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                                    {/* <Tooltip /> */}
                                    <Area type="monotone" dataKey="uv" stroke="#027FFF" fillOpacity={1} fill="url(#colorUv)" />
                                </AreaChart>
                            </div>

                        </div>

                    </div>

                    <div className={styles.cardsWrapper} >
                        <div className={styles.topPerformerWrapper}>
                            <div className={styles.tpwWrapper}>
                                <div >
                                    <div className={styles.tpwTitle}>
                                        Performers Stats
                                    </div>
                                    <div className={styles.tpwSubTitle}>
                                        Last 2 weeks
                                    </div>
                                </div>
                                <div>
                                    <IoEllipsisVertical />
                                </div>
                            </div>
                        </div>
                        <div className={styles.tasksWrapper}>
                            <div className={styles.tpwWrapper}>
                                <div >
                                    <div className={styles.tpwTitle}>
                                        Tasks
                                    </div>
                                    <div className={styles.tpwSubTitle}>
                                        {allTasks?.filter((item) => item?.isDone === '1')?.length} of {allTasks?.length} tasks completed
                                    </div>
                                </div>
                                <div>
                                    <IoEllipsisVertical />
                                </div>
                            </div>
                            <div className={styles.tasksListWrapper}>
                                <div className={styles.allTask}>
                                    {allTasks?.map((item, index) => (
                                        <div key={index} className={styles.eachTask}
                                        >
                                            <div className={styles.eachTaskLeft}
                                                onClick={togglingTaskStatus === false ? () => toggleTaskStatus(item?.taskIdentifier) : () => alert("Please wait..")}
                                            >
                                                <div className={styles.checkIcon}>
                                                    {item?.isDone === '1' ?
                                                        <MdCheckBox size={20} color="#70B6C1" />
                                                        :
                                                        <MdCheckBoxOutlineBlank size={20} />
                                                    }
                                                </div>
                                                <div className={styles.task}>
                                                    {item?.task}
                                                </div>
                                            </div>
                                            <div className={styles.deleteIcon}
                                                onClick={() => deleteTaskHandler(item?.taskIdentifier)}
                                            >
                                                <MdDeleteOutline size={20} />
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div>
                                    <div className={styles.addNewTask}>
                                        <div className={styles.addTaskInput}>
                                            <input placeholder="Add new task to do..." value={task} onChange={(e) => setTask(e.target.value)} />
                                        </div>
                                        <div className={styles.addTask}>
                                            {isAddingTask === true ?
                                                <button  >Adding..</button>
                                                :
                                                <button onClick={() => addTask()} >Add</button>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Overview