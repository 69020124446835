import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './OutstandingReport.module.css';
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { BiCalendar } from 'react-icons/bi';
import DateRangeSelector from '../../Components/UI/DateRangeSelector';
import { convertToAmPm, humanReadableTimePassed } from '../../Helper/calculations';
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import Chart from 'react-google-charts';

const OutstandingReport = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return (asCtx?.activeStore && asCtx.activeStore.trim() !== "")
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [allExpenses, setAllExpenses] = useState([])
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }
    const [includeExpenses, setIncludeExpenses] = useState(false)
    const { toPDF, targetRef } = usePDF({ filename: `sd.pdf` });
    const [collectionReport, setCollectionReport] = useState(null)
    const options = {
        resolution: Resolution.HIGH,
        // resolution: Resolution.NORMAL,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        // contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.width = '11.5in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    function calculateTotalAmount(expenses) {
        return expenses.reduce((total, expense) => {
            const amount = parseFloat(expense?.currentBalance) || 0; // Convert to number, default to 0 if invalid
            return total + amount;
        }, 0).toFixed(2); // Ensure the result is formatted to two decimal places
    }



    const getOutstandingReport = async () => {
        const getOutstandingReportResponse = await fetch(BASE_URL_API + "getOutstandingReport",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    startDate : dates?.startDate,
                    endDate : dates?.endDate
                })
            });

        if (!getOutstandingReportResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getOutstandingReportRespo = await getOutstandingReportResponse.json()
            if (getOutstandingReportRespo.status === "success") {
                setAllExpenses(getOutstandingReportRespo?.response)
            } else {
                if (getOutstandingReportRespo?.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllExpenses([])
                }
            }
            console.log(getOutstandingReportRespo)
        }
    }

    useEffect(() => {
        getOutstandingReport()
    }, [activeStore?.storeIdentifier, dates?.startDate, dates?.endDate])

    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    } else {
        return (
            <div className={styles.masterContainer}>
                <div className={styles.crTitle} >
                    <div>
                        <button className={styles.downloadPdfBtn} onClick={() => downloadPdfHandler()} >Download PDF Report</button>
                    </div>
                    <div className={styles.dateWrapper}>
                        <div className={styles.dateRangeSelectorWrapperBtn}
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            {dates.startDate === dates.endDate ?
                                <div>
                                    {dates.startDate} &nbsp;
                                </div>
                                :
                                <div>
                                    From {dates.startDate} to {dates.endDate} &nbsp;
                                </div>
                            }

                            <BiCalendar />
                        </div>
                        <DateRangeSelector
                            isOpen={isDatePickerOpen}
                            closeModal={closeModal}
                            changeDateHandler={changeDateHandler}
                        />

                    </div>
                </div>
                <div className={styles.mainWrapper} id="content-id">
                    <header className={styles.header} >
                        <h1>Outstanding Report</h1>
                        <p>{activeStore?.storeName}</p>
                        <p>{activeStore?.storeAddress}</p>
                        <p>{Date()}</p>
                    </header>

                    <section className={styles.patientDetails}>
                        <h2>Expenses Reports</h2>
                        <div className={styles.detailsRow}>
                            <span className={styles.id}>#</span>
                            <span className={styles.name}>Name</span>
                            <span className={styles.gender}>Gender</span>
                            <span className={styles.phone}>Phone</span>
                            <span className={styles.amount}>Current Outstanding</span>
                            <span className={styles.date}>DATE</span>
                            <span className={styles.time}>Pending Since</span>
                        </div>
                        {allExpenses?.map((item, index) => (
                            <div>
                                <div className={styles.detailsRow} key={index}>
                                    <span className={styles.id}>{index+1}.</span>
                                    <span className={styles.name}>{item?.client_name}</span>
                                    <span className={styles.gender}>{item?.gender}</span>
                                    <span className={styles.phone}>{item?.mobile}</span>
                                    <span className={styles.amount}>₹{item?.currentBalance*(-1)}</span>
                                    <span className={styles.date}>{item?.transactionDate?.substr(0, 10)}</span>
                                    <span className={styles.time}>{humanReadableTimePassed(item?.transactionDate)}</span>
                                </div>
                                <div className={styles.cartItemWrapper}>

                                </div>
                            </div>
                        ))}
                    </section>

                    <section className={styles.summarySection}>
                        <div className={`${styles.summaryRow} ${styles.borderTop}`}>
                            <span>Total Outstanding :</span>
                            <span>₹{parseFloat(calculateTotalAmount(allExpenses)*(-1)).toFixed(2)} </span>
                        </div>
                    </section>
                    
                    <section className={styles.endOfTheReport}>
                        End of the Report!
                    </section>
                </div>
            </div>
        );
    }
};

export default OutstandingReport;
