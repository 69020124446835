import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './ExpensesReport.module.css';
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { BiCalendar } from 'react-icons/bi';
import DateRangeSelector from '../../Components/UI/DateRangeSelector';
import { convertToAmPm } from '../../Helper/calculations';
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import Chart from 'react-google-charts';

const ExpensesReport = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return (asCtx?.activeStore && asCtx.activeStore.trim() !== "")
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [allExpenses, setAllExpenses] = useState([])
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }
    const [includeExpenses, setIncludeExpenses] = useState(false)
    const { toPDF, targetRef } = usePDF({ filename: `sd.pdf` });
    const [collectionReport, setCollectionReport] = useState(null)
    const options = {
        resolution: Resolution.HIGH,
        // resolution: Resolution.NORMAL,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        // contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.width = '11.5in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };

    function calculateTotalAmount(expenses) {
        return expenses.reduce((total, expense) => {
            const amount = parseFloat(expense.amount) || 0; // Convert to number, default to 0 if invalid
            return total + amount;
        }, 0).toFixed(2); // Ensure the result is formatted to two decimal places
    }



    const getExpenses = async () => {
        const getExpensesResponse = await fetch(BASE_URL_API + "getExpenses",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    startDate : dates?.startDate,
                    endDate : dates?.endDate
                })
            });

        if (!getExpensesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getExpensesRespo = await getExpensesResponse.json()
            if (getExpensesRespo.status === "success") {
                setAllExpenses(getExpensesRespo?.response)
            } else {
                if (getExpensesRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setAllExpenses([])
                }
            }
            console.log(getExpensesRespo)
        }
    }

    useEffect(() => {
        getExpenses()
    }, [activeStore?.storeIdentifier, dates?.startDate, dates?.endDate])

    const optionsPieChart = {
        title: "Category Wise Expenses",
    };
    const optionsPieChart2 = {
        title: "Sub category Wise Expenses",
    };

    const optionsLineChart = {
        title: "Expenses Trends",
        curveType: "function",
        legend: { position: "bottom" },
    };

    function convertServicesToPlot(services) {
        const servicesStatsToPlot = [
            ["Service", "Total Count"] // Header for the chart
        ];

        services.forEach(service => {
            servicesStatsToPlot.push([service.title, service.totalAmount]);
        });

        return servicesStatsToPlot;
    }

    function convertServicesToPlotLine(services) {
        const servicesStatsToPlot = [
            ["Date", "Amount"] // Header for the chart
        ];

        services.forEach(service => {
            servicesStatsToPlot.push([service.date, service.totalAmount]);
        });
        console.log(servicesStatsToPlot)
        return servicesStatsToPlot;
    }


    function getCategoryWiseExpenses(expenses) {
        if (!Array.isArray(expenses)) {
            console.error("Invalid input: Expenses must be an array.");
            return [];
        }

        const categoryMap = expenses.reduce((result, expense) => {
            if (!expense || typeof expense !== "object") {
                console.warn("Skipping invalid expense item:", expense);
                return result;
            }

            const category = expense.category && typeof expense.category === "string" ? expense.category : "Uncategorized";
            const amount = parseFloat(expense.amount) || 0;

            if (!result[category]) {
                result[category] = { title: category, totalAmount: 0, totalTransaction: 0 };
            }

            result[category].totalAmount += amount;
            result[category].totalTransaction += 1;

            return result;
        }, {});

        return Object.values(categoryMap);
    }

    function getSubCategoryWiseExpenses(expenses) {
        if (!Array.isArray(expenses)) {
            console.error("Invalid input: Expenses must be an array.");
            return [];
        }

        const subCategoryMap = expenses.reduce((result, expense) => {
            if (!expense || typeof expense !== "object") {
                console.warn("Skipping invalid expense item:", expense);
                return result;
            }

            const subCategory = expense.subCategory && typeof expense.subCategory === "string" ? expense.subCategory : "Uncategorized";
            const amount = parseFloat(expense.amount) || 0;

            if (!result[subCategory]) {
                result[subCategory] = { title: subCategory, totalAmount: 0, totalTransaction: 0 };
            }

            result[subCategory].totalAmount += amount;
            result[subCategory].totalTransaction += 1;

            return result;
        }, {});

        return Object.values(subCategoryMap);
    }

    function getDateWiseExpenses(period, data) {
        if (!Array.isArray(data)) {
            console.error("Invalid input: Data must be an array.");
            return [];
        }

        if (!["daily", "weekly", "monthly"].includes(period)) {
            console.error("Invalid period: Must be 'daily', 'weekly', or 'monthly'.");
            return [];
        }

        const groupByDate = (date, period) => {
            const parsedDate = new Date(date);
            if (isNaN(parsedDate)) {
                console.warn("Skipping invalid date:", date);
                return "Invalid Date";
            }

            switch (period) {
                case "daily":
                    return parsedDate.toISOString().split("T")[0]; // YYYY-MM-DD
                case "weekly":
                    const weekStart = new Date(parsedDate);
                    weekStart.setDate(parsedDate.getDate() - parsedDate.getDay());
                    return weekStart.toISOString().split("T")[0]; // Week start YYYY-MM-DD
                case "monthly":
                    return `${parsedDate.getFullYear()}-${String(parsedDate.getMonth() + 1).padStart(2, "0")}`; // YYYY-MM
                default:
                    return "Invalid Period";
            }
        };

        const dateMap = data.reduce((result, expense) => {
            if (!expense || typeof expense !== "object") {
                console.warn("Skipping invalid expense item:", expense);
                return result;
            }

            const dateKey = groupByDate(expense.createdOn, period);
            const amount = parseFloat(expense.amount) || 0;

            if (!result[dateKey]) {
                result[dateKey] = { date: dateKey, totalAmount: 0, totalTransaction: 0 };
            }

            result[dateKey].totalAmount += amount;
            result[dateKey].totalTransaction += 1;

            return result;
        }, {});

        return Object.values(dateMap).filter(item => item.date !== "Invalid Date");
    }




    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    } else {
        return (
            <div className={styles.masterContainer}>
                <div className={styles.crTitle} >
                    <div>
                        <button className={styles.downloadPdfBtn} onClick={() => downloadPdfHandler()} >Download PDF Report</button>
                    </div>
                    <div className={styles.dateWrapper}>
                        <div className={styles.dateRangeSelectorWrapperBtn}
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            {dates.startDate === dates.endDate ?
                                <div>
                                    {dates.startDate} &nbsp;
                                </div>
                                :
                                <div>
                                    From {dates.startDate} to {dates.endDate} &nbsp;
                                </div>
                            }

                            <BiCalendar />
                        </div>
                        <DateRangeSelector
                            isOpen={isDatePickerOpen}
                            closeModal={closeModal}
                            changeDateHandler={changeDateHandler}
                        />

                    </div>
                </div>
                <div className={styles.mainWrapper} id="content-id">
                    <header className={styles.header} >
                        <h1>Expenses Report</h1>
                        <p>{activeStore?.storeName}</p>
                        <p>{activeStore?.storeAddress}</p>
                        <p>{Date()}</p>
                    </header>

                    <div className={styles.graphsWrapper}>
                        <div className={styles.graphContainer}>
                            <Chart
                                chartType="PieChart"
                                data={convertServicesToPlot(getCategoryWiseExpenses(allExpenses))}
                                options={optionsPieChart}
                                width={450}
                                height={400}
                            />
                        </div>
                        <div className={styles.graphContainer}>
                            <Chart
                                chartType="PieChart"
                                data={convertServicesToPlot(getSubCategoryWiseExpenses(allExpenses))}
                                options={optionsPieChart2}
                                width={450}
                                height={400}
                            />
                        </div>

                        <div className={styles.graphContainer}>
                            <Chart
                                chartType="LineChart"
                                data={convertServicesToPlotLine(getDateWiseExpenses("daily", allExpenses))}
                                options={optionsLineChart}
                                width={420}
                                height={340}
                            />
                        </div>


                    </div>

                    <section className={styles.patientDetails}>
                        <h2>Expenses Reports</h2>
                        <div className={styles.detailsRow}>
                            <span className={styles.id}>#Exp</span>
                            <span className={styles.date}>DATE</span>
                            <span className={styles.category}>CATEGORY</span>
                            <span className={styles.subCategory}>SUB CATEGORY</span>
                            <span className={styles.expenseName}>TITLE</span>
                            <span className={styles.expenseNotes}>NOTES</span>
                            <span className={styles.amount}>AMOUNT</span>
                            <span className={styles.time}>PAYMENT TIME</span>
                        </div>
                        {allExpenses?.map((item, index) => (
                            <div>
                                <div className={styles.detailsRow} key={index}>
                                    <span className={styles.id}>EXP/24-25/{item?.ID}</span>
                                    <span className={styles.date}>{item?.createdOn?.substr(0, 10)}</span>
                                    <span className={styles.category}>{item?.category}</span>
                                    <span className={styles.subCategory}>{item?.subCategory}</span>
                                    <span className={styles.expenseName}>{item?.expenseName}</span>
                                    <span className={styles.expenseNotes}>{item?.expenseNotes}</span>
                                    <span className={styles.amount}>₹{item?.amount}</span>
                                    <span className={styles.time}>{convertToAmPm(item?.createdOn?.substr(11, 8))}</span>
                                </div>
                                <div className={styles.cartItemWrapper}>

                                </div>
                            </div>
                        ))}
                    </section>

                    <section className={styles.summarySection}>
                        <div className={`${styles.summaryRow} ${styles.borderTop}`}>
                            <span>Total Expense :</span>
                            <span>₹{calculateTotalAmount(allExpenses)} </span>
                        </div>
                        {includeExpenses === true &&
                            <div className={styles.summaryRow}>
                                <span>Total Expenses:</span>
                                <span className={styles.dangerTxt}>
                                    {parseFloat(collectionReport?.totalExpense || 0) < 0 ? '-' : ''}
                                    ₹{Math.abs(parseFloat(collectionReport?.totalExpense || 0)).toFixed(2)}
                                </span>
                            </div>
                        }
                    </section>
                    
                    <section className={styles.endOfTheReport}>
                        End of the Report!
                    </section>
                </div>
            </div>
        );
    }
};

export default ExpensesReport;
