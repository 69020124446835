import React from 'react'
import { monthNumToTxtSmall, toDayMonthYear } from '../../Helper/calculations'
import styles from '../Users/EachVisitWrapper.module.css'
import { Link } from 'react-router-dom'


const EachVisitWrapper = props => {
    const item = props.item
    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div>
                    {toDayMonthYear(item.payStatusLastUpdatedAt)[0]}-
                    {monthNumToTxtSmall(toDayMonthYear(item.payStatusLastUpdatedAt)[1])}'
                    {toDayMonthYear(item.payStatusLastUpdatedAt)[2]}

                </div>
                <div>
                    &#8377;{item.amountToBeCollected}/-
                </div>
            </div>
            <div>
                <Link to={"/booking/"+item.bookingId}>Go</Link>
            </div>

        </div>
    )
}

export default EachVisitWrapper