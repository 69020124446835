import React, { useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import styles from './ConfigMembershipCards.module.css'
import { useParams, useNavigate } from 'react-router';
import ConfigMembershipCardsHeader from '../../Components/Membership/ConfigMembershipCardsHeader';
import ConfigMembershipCreateNewCard from './ConfigMembershipCreateNewCard';

const ConfigMembershipCards = () => {
    const params = useParams()
    const selectedCard = params.selectedCard





    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            <div className={styles.mainContainer}>
                <ConfigMembershipCardsHeader selectedCard={selectedCard} />
                {selectedCard === 'create-a-new-card' && <ConfigMembershipCreateNewCard />}

                {/* <div className={styles.mainBody}>
                    <div className={styles.contentBodyWrapper}>
                        <div className={styles.contentBodyContainer}>
                            All cards will appear here...
                        </div>
                    </div>
                </div> */}

            </div>
        </div>
    )
}

export default ConfigMembershipCards