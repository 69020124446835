import React, { useContext, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import EachUserWrapper from '../Content/Users/EachUserWrapper';
import Login from '../Content/AuthPages/Login';
import BookingDetails from '../Content/Bookings/BookingDetails';
import CurrentSessions from '../Content/Bookings/CurrentSessions';
import FeedbackHome from '../Content/Feedback/FeedbackHome';
import AddNewMember from '../Content/Membership/AddNewMember';
import AllMembers from '../Content/Membership/AllMembers';
import CardDetails from '../Content/Membership/CardDetails';
import Home from '../Content/Pages/Home';
import ManageServices from '../Content/Services/ManageServices';
import ActiveSessions from '../Content/Sessions/ActiveSessions';
import Users from '../Content/Users/Users';
import AuthContext from '../store/auth-context';
import Ledger from '../Content/StoreLedger/Ledger';
import ManageStaff from '../Content/StaffManagement/ManageStaff';
import MonthlyRevenue from '../Content/Analytics/MonthlyRevenue';
import MusicPlayer from '../Content/Music/MusicPlayer';
import ConfigMembershipCards from '../Content/Membership/ConfigMembershipCards';
import Overview from '../Content/Dashboard/Overview';
import LeftMenu from '../Components/Menu/LeftMenu';
import styles from './MainNavigator.module.css'
import UpperHeader from '../Components/Menu/UpperHeader';
import AllStores from '../Content/Stores/AllStores';
import StoreDetails from '../Content/Stores/StoreDetails';
import StoreEmployees from '../Content/Stores/StoreEmployees';
import StorePriceList from '../Content/Stores/StorePriceList';
import StoreClients from '../Content/Stores/StoreClients';
import NotFound from '../Content/404/NotFound';
import AddClients from '../Content/Users/AddClients';
import EventCalendar from '../Content/Calendar/EventCalendar';
import NetworkStatus from '../Components/UI/NetworkStatus';
import ManageEachEmployee from '../Content/StaffManagement/ManageEachEmployee';
import ManageAccess from '../Content/StaffManagement/ManageAccess';
import AllInvoices from '../Content/Invoices/AllInvoices';
import CofAlpha from '../Content/ClientOnboardingForm/CofAlpha';
import ManageUserRegistrationPages from '../Content/ClientOnboardingForm/ManageUserRegistrationPages';
import UpdateService from '../Components/Services/UpdateService';
import ExpensesHome from '../Content/Expenses/ExpensesHome';
import CollectionReport from '../Content/Dashboard/CollectionReport';
import ExpensesReport from '../Content/Dashboard/ExpensesReport';
import PriceListSettings from '../Content/Settings/PriceListSettings';
import OutstandingReport from '../Content/Dashboard/OutstandingReport';

const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(true)
    const menuToggler = () => setIsMenuOpen(!isMenuOpen)

    const accessibilityData = JSON.parse(authCtx?.loginData)?.accessibilityData
    // console.log(JSON.parse(authCtx?.loginData))

    if (!authCtx.isLoggedIn) {
        return (
            <div className={styles.mainWrapper}>
                <NetworkStatus />

                <Routes>
                    <Route path="*" element={<Login />}></Route>
                    <Route path="/" element={<Login />}></Route>
                    <Route path="/ur/:pageKey" element={<CofAlpha />}></Route>
                </Routes>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <NetworkStatus />
                <div className={isMenuOpen !== true ? styles.open : styles.close}>
                    <LeftMenu isOpen={isMenuOpen} toggler={menuToggler} />
                </div>
                <div className={styles.rightCol}>
                    <div className={styles.upperHeaderWrapper}>
                        <UpperHeader />
                    </div>
                    <Routes>
                        <Route path="*" element={<NotFound />}></Route>
                        {/* <Route path="/" element={<Home />}></Route> */}
                        <Route path="/" element={<Overview />}></Route>
                        {/* <Route path="/ur/:pageKey" element={<CofAlpha />}></Route> */}
                        {accessibilityData?.includes("/overview") && <Route path="/overview" element={<Overview />}></Route>}
                        {accessibilityData?.includes("/manage-stores") && <Route path="/manage-stores" element={<AllStores />}></Route>}
                        {accessibilityData?.includes("/manage/details") && <Route path="/manage/details" element={<StoreDetails />}></Route>}
                        {accessibilityData?.includes("/manage/employees") && <Route path="/manage/employees" element={<StoreEmployees />}></Route>}
                        {accessibilityData?.includes("/manage/price-list") && <Route path="/manage/price-list" element={<StorePriceList />}></Route>}
                        {/* <Route path="/manage/price-list/update-item/:serviceIdentifier" element={<UpdateService />}></Route> */}
                        <Route path="/manage/price-list/update-item/:serviceIdentifier" element={<StorePriceList />}></Route>

                        {accessibilityData?.includes("/manage/clients") && <Route path="/manage/clients" element={<StoreClients />}></Route>}
                        {accessibilityData?.includes("/services") && <Route path="/services" element={<ManageServices />}></Route>}
                        {accessibilityData?.includes("/users") && <Route path="/users" element={<Users />}></Route>}
                        <Route path="/users" element={<Users />}></Route>
                        <Route path="/user/:clientIdentifier" element={<EachUserWrapper />}></Route>
                        {accessibilityData?.includes("/user/:clientIdentifier") && <Route path="/user/:clientIdentifier" element={<EachUserWrapper />}></Route>}
                       
                        <Route path="/memberships" element={<AllMembers />}></Route>
                        {accessibilityData?.includes("/memberships") && <Route path="/memberships" element={<AllMembers />}></Route>}
                        {accessibilityData?.includes("/memberships/sell") && <Route path="/memberships/sell" element={<AddNewMember />}></Route>}
                        {accessibilityData?.includes("/invoices") && <Route path="/invoices" element={<AllInvoices />}></Route>}
                        {accessibilityData?.includes("/invoice/:invoiceIdentifier") && <Route path="/invoice/:invoiceIdentifier" element={<AllInvoices />}></Route>}
                        {/* {accessibilityData?.includes("/reports") && <Route path="/reports" element={<AllInvoices />}></Route>}
                        {accessibilityData?.includes("/reports/:invoiceIdentifier") && <Route path="/reports/:invoiceIdentifier" element={<AllInvoices />}></Route>} */}
                        
                        

                        <Route path="/membership/:purchaseCardIdentifier" element={<CardDetails />}></Route>
                        <Route path="/bookings" element={<CurrentSessions />}></Route>
                        <Route path="/booking/:bookingIdentifier" element={<BookingDetails />}></Route>
                        {accessibilityData?.includes("/sessions") && <Route path="/sessions" element={<ActiveSessions />}></Route>}
                        {accessibilityData?.includes("/sessions/:sessionIdentifier") && <Route path="/sessions/:sessionIdentifier" element={<ActiveSessions />}></Route>}
                        <Route path="/feedbacks" element={<FeedbackHome />}></Route>
                        <Route path="/ledger" element={<Ledger />}></Route>
                        <Route path="/staff" element={<ManageStaff />}></Route>
                        <Route path="/analytics" element={<MonthlyRevenue />}></Route>

                        {accessibilityData?.includes("/add-clients") && <Route path="/add-clients" element={<AddClients />}></Route>}
                        {accessibilityData?.includes("/calendar") && <Route path="/calendar" element={<EventCalendar />}></Route>}
                        {accessibilityData?.includes("/store/employee/:employeeIdentifier") && <Route path="/store/employee/:employeeIdentifier" element={<ManageEachEmployee />}></Route>}
                        {accessibilityData?.includes("/manage-access/:authIdentifier") && <Route path="/manage-access/:authIdentifier" element={<ManageAccess />}></Route>}
                        
                        {accessibilityData?.includes("/user-registration-forms") && <Route path="/user-registration-forms" element={<ManageUserRegistrationPages />}></Route>}

                        
                        <Route path="/config/membership-card/:selectedCard" element={<ConfigMembershipCards />}></Route>
                        <Route path="/expenses" element={<ExpensesHome />}></Route>
                        <Route path="/collection-reports" element={<CollectionReport />}></Route>
                        
                        <Route path="/expenses-report" element={<ExpensesReport />}></Route>
                        <Route path="/outstanding-report" element={<OutstandingReport />}></Route>
                        
                        {/* <Route path="/manage-refers" element={<CollectionReport />}></Route> */}



                        {/* <Route path="/music" element={<MusicPlayer />}></Route> */}
                        {/* <Route component={MusicPlayer} ></Route> */}

                        {/* adding this on 20th November 2024 */}
                        <Route path="/settings/pricelist" element={<PriceListSettings />}></Route>

                    </Routes>
                </div>
            </div>
        )
    }

}

export default MainNavigator