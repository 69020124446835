import React, { useContext, useRef, useState } from 'react';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import { useReactToPrint } from 'react-to-print';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './PrintCollectionReceipt.module.css';
import InvoiceBrandLogo from '../../Components/Invoices/InvoiceBrandLogo'
import { convertDateToDdMmYyyy, convertToAmPm, getAge } from '../../Helper/calculations';

const PrintCollectionReceipt = ({ isOpen, data, clientData, onClose }) => {
    const receiptRef = useRef(null); // Create a ref for the receipt content
    
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    // console.log(JSON.parse(authCtx.loginData)?.welcomeData?.displayName)
    // console.log(data)
    const [generateBillThroughOptions, setGenerateBillThroughOptions] = useState([
        {
            "storeIdentifier": "561726767687003789",
            "storeName": "RAJESHWARI AYURDHAMA",
            "storeAddress": "#607, Ravi Nenapu, 7th Main road, Havanoor Extn, Near Hesaraghatta Main Road, Bengaluru – 560073",
            "storeEmail": "rajeshwariayurdhama@gmail.com",
            "storePrimaryPhone": "8217368304",
            "storeSecondaryPhone": "8147042415",
            "storeTertiaryPhone": "",
            "invoiceLogo": "https://crm.utpalaayurdhama.com/rajeshwari-logo-web.png",
            "invoiceDefaultNotes": ""
        },
        {
            "storeIdentifier": "561727240952003789",
            "storeName": "UTPALA AYURDHAMA",
            "storeAddress": "New BEL Rd, Chikkamaranahalli, Dollars Colony, R.M.V. 2nd Stage, Bengaluru, Karnataka 560094",
            "storeEmail": "info@utpalaayurdhama.com",
            "storePrimaryPhone": "7259195959",
            "storeSecondaryPhone": "",
            "storeTertiaryPhone": "",
            "invoiceLogo": "https://crm.utpalaayurdhama.com/utpala-logo-web.png",
            "invoiceDefaultNotes": ""
        }
    ])

    const handlePrint = useReactToPrint({
        content: () => receiptRef.current, // Point to the receipt content
        documentTitle: 'Collection Receipt', // Optional: Title for the print document
        onAfterPrint: () => console.log('Print completed'), // Optional: Callback after printing
    });

    const options = {
        resolution: Resolution.MEDIUM,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        // contentElement.style.width = '8.27in'; // A4 width in inches
        // contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };


    if (!isOpen) return null; // Ensure the receipt is only shown when `isOpen` is true

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    {/* The receipt content */}
                    <div className={styles.printWrapper} id="content-id" ref={receiptRef}>
                        <div className={styles.header}>
                            <div className={styles.topWrapper}>
                                <div className={styles.twFirstRow}>
                                    <div className={styles.businessWrapper}>
                                        <div>

                                            <InvoiceBrandLogo url={activeStore?.invoiceLogo} />
                                            <div className={styles.storeName}>
                                                {activeStore?.storeName}
                                            </div>
                                            <div className={styles.storeAddress}>
                                                {activeStore?.storeAddress}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.twSecondRow}>
                                    <div className={styles.clientWrapper}>
                                        <div>
                                            <div className={`${styles.clientName} ${styles.eachPdRow}`}>
                                                <div className={styles.pdTitle}>
                                                    Name
                                                </div>
                                                <div className={styles.pdDivider}>:</div>
                                                {clientData?.salutation} {clientData?.client_name}
                                            </div>

                                            <div className={`${styles.age} ${styles.eachPdRow}`}>
                                                <div className={styles.pdTitle}>
                                                    Age / Gender
                                                </div>
                                                <div className={styles.pdDivider}>:</div>
                                                {getAge(clientData?.client_birthday)} years
                                                {clientData?.gender ? `, ${clientData.gender}` : ''}
                                            </div>

                                            <div className={`${styles.phoneNumber} ${styles.eachPdRow}`}>
                                                <div className={styles.pdTitle}>
                                                    Phone
                                                </div>
                                                <div className={styles.pdDivider}>:</div>
                                                +91-{clientData?.mobile}{clientData?.alternateNumber ? `, ${clientData.alternateNumber}` : ''}
                                            </div>
                                            <div className={`${styles.clientEmail} ${styles.eachPdRow}`}>
                                                <div className={styles.pdTitle}>
                                                    E-mail
                                                </div>
                                                <div className={styles.pdDivider}>:</div>
                                                {clientData?.client_email}
                                            </div>
                                            <div className={`${styles.clientAddress} ${styles.eachPdRow}`}>
                                                <div className={styles.pdTitle}>
                                                    Address
                                                </div>
                                                <div className={styles.pdDivider}>:</div>
                                                <div className={styles.pdValue}>
                                                    {clientData?.client_address}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.taxWrapper}>
                                        <div className={styles.taxTitle} >
                                            <div>
                                                RECEIPT DETAILS
                                            </div>
                                        </div>
                                        <div className={styles.taxDetails}>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Receipt No:</div>
                                                <div className={styles.etrValue}>ODCR/24-25/{data?.ID}</div>
                                            </div>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Date</div>
                                                <div className={styles.etrValue}>{convertDateToDdMmYyyy(data?.transactionDate?.substring(0, 10))}</div>
                                            </div>
                                            <div className={styles.eachTdRow}>
                                                <div className={styles.etrTitle}>Time</div>
                                                <div className={styles.etrValue}>{convertToAmPm(data?.transactionDate?.substring(10, 20).trim())}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.content}>
                            <div className={styles.row}>
                                <span>Transaction Type:</span>
                                <span>{data?.transactionType || 'N/A'}</span>
                            </div>
                            <div className={styles.row}>
                                <span>Amount Collected:</span>
                                <span>₹{data?.amount || 'N/A'}</span>
                            </div>
                            <div className={styles.row}>
                                <span>Collected By:</span>
                                <span>{JSON.parse(authCtx.loginData)?.welcomeData?.displayName || 'N/A'}</span>
                            </div>
                            {/* <div className={styles.row}>
                                <span>Remarks:</span>
                                <span>{data?.remarks || 'N/A'}</span>
                            </div> */}
                            <div className={styles.additionalWrapper}>
                                <div className={styles.transValueWrapper}>
                                    <div className={`${styles.eachRow} ${styles.bold}`}>
                                        <div className={styles.eachRowTitle}>Collection Via Card</div>
                                        <div className={styles.eachRowValue}>₹{data?.collectionViaCard}</div>
                                    </div>
                                    <div className={`${styles.eachRow} ${styles.rightAlign}`}>
                                        <div className={styles.eachRowTitle}>Card Last 4 digits</div>
                                        <div className={styles.eachRowValue}>{data?.cardLastDigits}</div>
                                    </div>

                                    <div className={`${styles.eachRow} ${styles.bold}`}>
                                        <div className={styles.eachRowTitle}>Collection Via Cash</div>
                                        <div className={styles.eachRowValue}>₹{data?.collectionViaCash}</div>
                                    </div>
                                    <div className={`${styles.eachRow} ${styles.bold}`}>
                                        <div className={styles.eachRowTitle}>Collection Via QR</div>
                                        <div className={styles.eachRowValue}>₹{data?.collectionViaQr}</div>
                                    </div>
                                    <div className={`${styles.eachRow} ${styles.rightAlign}`}>
                                        <div className={styles.eachRowTitle}>Qr Code Txn No.</div>
                                        <div className={styles.eachRowValue}>{data?.qrTxnRefId}</div>
                                    </div>
                                </div>
                                <div className={styles.currentBalanceWrapper}>
                                    <div className={styles.eachRowTitle}>Current Balance</div>
                                    <div className={styles.eachRowValue}>₹{data?.currentBalance}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <div className={styles.invoiceFooter}>
                                <div className={`${styles.ifLeftCol} ${styles.eachIfCol}`}>
                                    <div className={styles.ifColTitle}>Reach Us At</div>
                                    <div className={styles.ifColVal}>
                                        <div className={styles.eachIfColVal}>
                                            <MdEmail size={14} />&nbsp;{activeStore?.storeEmail}
                                        </div>
                                        <div>
                                            <div>
                                                <FaPhoneAlt size={14} />&nbsp;+91-{activeStore?.storePrimaryPhone}
                                            </div>
                                            {activeStore?.storeSecondaryPhone !== null &&
                                                <div>
                                                    <FaPhoneAlt size={14} />&nbsp;{activeStore?.storeSecondaryPhone}
                                                </div>
                                            }
                                            {activeStore?.storeTertiaryPhone !== '' &&
                                                <div>
                                                    <FaPhoneAlt size={14} />&nbsp;+91-{activeStore?.storeTertiaryPhone}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.ifRightCol} ${styles.eachIfCol}`}>
                                    <div className={styles.ifColTitle}>Our Branch(s)</div>
                                    <div className={styles.ifColVal}>
                                        {generateBillThroughOptions.filter((item) => item?.storeIdentifier !== activeStore?.storeIdentifier)?.map((itemNew, indexNew) => (
                                            <div key={indexNew} className={styles.eachBranchDetails} >
                                                <div className={styles.eachIfColVal}>
                                                    <MdLocationPin />&nbsp;
                                                    {itemNew?.storeName}
                                                </div>
                                                <div className={styles.eachStoreAddress}>
                                                    {itemNew?.storeAddress}
                                                </div>
                                                <div className={styles.eachStoreEmail}>
                                                    {itemNew?.storeEmail}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={styles.actionWrapper}>
                    {/* The print button */}
                    <button onClick={() => onClose()} className={styles.closeBtn}>
                        Close
                    </button>
                    <button onClick={downloadPdfHandler} className={styles.printButton}>
                        Print Receipt
                    </button>
                </div>





            </div>
        </div>
    );
};

export default PrintCollectionReceipt;
