import React, { useRef, useEffect, useState, useContext } from 'react';
import { sanitizeToPositiveNumber } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './CollectLedgerBalance.module.css';

const CollectLedgerBalance = (props) => {
    const { isOpen, onClose, clientStoreRelationIdentifier, onSuccess } = props;
    const modalRef = useRef(null); // Create a ref for the modal
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const [isUpdating, setIsUpdating] = useState(false)
    const [amount, setAmount] = useState('')
    const [collectionViaCash, setCollectionViaCash] = useState('')
    const [collectionViaCard, setCollectionViaCard] = useState('')
    const [collectionViaQr, setCollectionViaQr] = useState('')
    const [collectionViaLink, setCollectionViaLink] = useState('')
    const [qrTxnRefId, setQrTxnRefId] = useState('')
    const [cardLastDigits, setCardLastDigits] = useState('')
    const [isAmountValidated, setIsAmountValidated] = useState(false)




    useEffect(() => {
        const handleClickOutside = (event) => {
            // Check if the clicked element is outside the modal
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose(); // Trigger close if clicked outside
            }
        };

        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                onClose(); // Trigger close on Escape key press
            }
        };

        // Attach event listeners for both clicks and keydowns
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscape);

        // Clean up the event listeners when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const collectLedgerBalance = async () => {
        if (window.confirm("Are you sure, you want to update the ledger!")) {
            setIsUpdating(true)
            const collectLedgerBalanceResponse = await fetch(BASE_URL_API + "collectLedgerBalance",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx?.token,
                        clientStoreRelationIdentifier: clientStoreRelationIdentifier,
                        storeIdentifier : activeStore?.storeIdentifier,
                        amount: amount,
                        collectionViaCash : collectionViaCash,
                        collectionViaCard : collectionViaCard,
                        collectionViaQr : collectionViaQr,
                        collectionViaLink : collectionViaLink,
                        qrTxnRefId : qrTxnRefId,
                        cardLastDigits : cardLastDigits
                    })
                });

            if (!collectLedgerBalanceResponse?.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const collectLedgerBalanceRespo = await collectLedgerBalanceResponse.json()
                if (collectLedgerBalanceRespo?.status === "success") {
                    onSuccess()
                } else {
                    if (collectLedgerBalanceRespo?.message === "tokenExpired") {
                        authCtx?.logout()
                    } else {
                        console.log(collectLedgerBalanceRespo)
                    }
                }
            }
            setIsUpdating(false)
        }
    }

    const validateBreakdown = () => {
        // Parse each collection value or default to 0 if NaN
        const qrAmount = parseFloat(collectionViaQr);
        const cashAmount = parseFloat(collectionViaCash);
        const cardAmount = parseFloat(collectionViaCard);
        
        // Ensure each parsed value is a valid number, or set it to 0 if it's NaN
        const total = 
            (Number.isNaN(qrAmount) ? 0 : qrAmount) +
            (Number.isNaN(cashAmount) ? 0 : cashAmount) +
            (Number.isNaN(cardAmount) ? 0 : cardAmount);
    
        // Parse amount as a float
        const expectedAmount = parseFloat(amount) || 0;
    
        // Set a small tolerance to handle floating-point precision issues
        const tolerance = 0.01;
    
        // Compare total and amount within the tolerance range
        setIsAmountValidated(Math.abs(total - expectedAmount) < tolerance);
    
        console.log(total);
    };
    

    useEffect(() => {
        validateBreakdown()
    }, [amount, collectionViaQr, collectionViaCash, collectionViaCard])



    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} >
                    <div className={styles.mainContent} ref={modalRef}>
                        <div className={styles.collectBalance}>
                            Collect Balance
                        </div>
                        <div>
                            <div className={styles.amountInput}>
                                <input placeholder="Enter Amount..." autoFocus type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(sanitizeToPositiveNumber(e.target.value))}
                                />
                            </div>
                        </div>
                        <div className={styles.modeWrapper}>
                            <div className={styles.eachModeWrapper}>
                                <div className={styles.emwTitle}>Cash Collected</div>
                                <div className={styles.emwValueWrapper}>

                                    <div className={styles.emwValue}>
                                        <input placeholder="Enter cash collected..." value={collectionViaCash}
                                            onChange={(e) => setCollectionViaCash(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eachModeWrapper}>
                                <div className={styles.emwTitle}>Collected via QR</div>
                                <div className={styles.emwValueWrapper}>
                                    <div className={styles.emwValue}>
                                        <input placeholder="Enter payment via QR"
                                            value={collectionViaQr}
                                            onChange={(e) => setCollectionViaQr(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.txnWrapper}>
                                        <input placeholder="Enter Txn. Ref. no..."
                                            value={qrTxnRefId}
                                            onChange={(e) => setQrTxnRefId(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.eachModeWrapper}>
                                <div className={styles.emwTitle}>Collected Via Card</div>
                                <div className={styles.emwValueWrapper}>
                                    <div className={styles.emwValue}>
                                        <input placeholder="Enter payment via card.."
                                            value={collectionViaCard}
                                            onChange={(e) => setCollectionViaCard(e.target.value)}
                                        />
                                    </div>
                                    <div className={styles.txnWrapper}>
                                        <input placeholder="Please card digits."
                                            value={cardLastDigits}
                                            onChange={(e) => setCardLastDigits(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.actionsWrapper}>
                            {isUpdating === true ?
                                <div className={styles.updateBtn}>
                                    <button>Updating...</button>
                                </div>
                                :
                                <div className={styles.updateBtn}>
                                    {isAmountValidated === true ?
                                        <button className={styles.enabled} onClick={() => collectLedgerBalance()} >Update</button>
                                        :
                                        <button className={styles.disabled} disabled >Update </button>
                                    }
                                </div>
                            }
                            <div className={styles.cancelBtn}>
                                <button>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default CollectLedgerBalance;
