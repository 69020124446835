import React, { useContext, useEffect, useState } from 'react'
import { FaMinus, FaPlus, FaTag, FaUser } from 'react-icons/fa';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/Cart.module.css'
import AddNewClient from '../Users/AddNewClient';
import AddFlatDiscount from './AddFlatDiscount';
import ChooseClient from './ChooseClient';
import CollectPayment from './CollectPayment';
import EachItemInCart from './EachItemInCart';

const Cart = props => {
    const authCtx = useContext(AuthContext);
    const cartItems = props.cartItems;
    const sessionIdentifier = props.sessionIdentifier
    const getCartItems = props.getCartItems
    const fetchingCartItems = props.fetchingCartItems

    const [chooseClientOpen, setChooseClientOpen] = useState(false)
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    const [userDetailsFetched, setUserDetailsFetched] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState({
        otp: ''
    })
    const [clientStoreRelationIdentifier, setClientStoreRelationIdentifier] = useState('')
    const userFormToggle = (k) => setIsUserFormOpen(k)
    const closeModal = () => setIsUserFormOpen(false)
    const abortClientSelection = () => setChooseClientOpen(false)
    const assignClient = (k) => {
        selectUserForBookingHandler(k)
        setClientStoreRelationIdentifier(k)
        abortClientSelection()
    }

    ///// this is for payment collection
    const [isCollecting, setIsCollecting] = useState(false);
    const abortCollection = () => {
        setIsCollecting(false)
    }
    ///// this is for payment collection



    const [netPayableAmount, setNetPayableAmount] = useState(0)
    const [netSavings, setNetSavings] = useState(0)

    const [totalServiceAmount, setTotalServiceAmount] = useState(0)
    const [totalServiceDiscountedAmount, setTotalServiceDiscountedAmount] = useState(0)
    const [flatDiscount, setFlatDiscount] = useState(0)
    const [flatDiscountPercentage, setFlatDiscountPercentage] = useState(0)
    const [flatDiscountBy, setFlatDiscountBy] = useState('A')


    /// this is for flat discount box
    const [isFlatDiscountOpen, setIsFlatDiscountOpen] = useState(false)
    const flatDiscountBoxController = () => {
        setIsFlatDiscountOpen(false)
    }

    const updateFlatDiscount = (k) => {
        setFlatDiscount(k)
    }

    const updateFlatDiscountPercentage = (k) => {
        setFlatDiscountPercentage(k)
    }

    const toggleDiscountBy = (k) => {
        setFlatDiscountBy(k)
    }

    const amountCalculator = () => {
        let netPayableTemp = 0;
        let netSavingsTemp = 0;
        let tsa = 0;
        let tsda = 0;
        cartItems.map((item, index) => {
            tsa = parseFloat(tsa) + parseFloat(parseFloat(item.quantity) * parseFloat(item.totalBasePrice));
            tsda = parseFloat(tsda) + parseFloat(parseFloat(item.quantity) * parseFloat(item.totalDiscPrice));
        })

        setTotalServiceAmount(parseFloat(tsa).toFixed(1))
        setTotalServiceDiscountedAmount(parseFloat(tsda).toFixed(1))

        netPayableTemp = tsda - parseFloat(flatDiscount !== '' ? flatDiscount : 0)
        setNetPayableAmount(parseFloat(netPayableTemp).toFixed(1));

        netSavingsTemp = parseFloat(tsa - tsda) + parseFloat(flatDiscount !== '' ? flatDiscount : 0)
        setNetSavings(parseFloat(netSavingsTemp).toFixed(1))

    }

    useEffect(() => {
        amountCalculator()
    }, [cartItems, flatDiscount])

    const getAssignedClientForBooking = async () => {
        const getAssignedClientForBookingResponse = await fetch(BASE_URL_API + "getAssignedClientForBooking",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    sessionIdentifier: sessionIdentifier
                })
            });

        if (!getAssignedClientForBookingResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAssignedClientForBookingRespo = await getAssignedClientForBookingResponse.json();
            if (getAssignedClientForBookingRespo.status === 'success') {
                setClientStoreRelationIdentifier(getAssignedClientForBookingRespo.response[0])
            } else {
                if (getAssignedClientForBookingRespo.status === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setClientStoreRelationIdentifier('')
                    console.log(getAssignedClientForBookingRespo);
                }
            }
        }
    }

    const selectUserForBookingHandler = async (k) => {
        console.log("printing k array : ", k)
        const selectUserForBookingResponse = await fetch(BASE_URL_API + "selectUserForBooking",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: sessionIdentifier,
                    storeIdentifier: authCtx?.storeIdentifier,
                    clientStoreRelationIdentifier: k?.clientStoreRelationIdentifier,
                    clientName: k?.client_name,
                    mobile: k?.mobile,
                    relativeIdentifier : k?.relativeIdentifier
                })
            });

        if (!selectUserForBookingResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const selectUserForBookingRespo = await selectUserForBookingResponse.json();
            if (selectUserForBookingRespo.status === 'success') {
                getAssignedClientForBooking()
            } else {
                if (selectUserForBookingRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setClientStoreRelationIdentifier('')
                }
            }
            console.log(selectUserForBookingRespo)
        }
    }

    useEffect(() => {
        if (sessionIdentifier !== '') {
            getAssignedClientForBooking()
        }
    }, [sessionIdentifier])


    // useEffect(() => {
    //     if (clientStoreRelationIdentifier !== '') {
    //         selectUserForBookingHandler()
    //     }
    // }, [clientStoreRelationIdentifier])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
                console.log("ctrl + c pressed", clientStoreRelationIdentifier)
                if(clientStoreRelationIdentifier !== "") {
                    if(clientStoreRelationIdentifier.clientDetailsContainer !== '') {
                        setIsCollecting(true)
                        console.log("collecting is triggered")
                    } else {
                        alert("Something went wrong, kindly refresh the page")
                    }
                } else {
                    setChooseClientOpen(true)
                    console.log("choose client triggered")
                }
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [clientStoreRelationIdentifier]);



    if (cartItems.length < 1) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.header}>
                    <div className={styles.totalSavingLine}>
                        No service(s) in the cart!
                    </div>
                </div>
                <div className={styles.body}>
                    <div className={styles.noItemInCart}>
                        You haven't added any services to the cart!
                    </div>
                </div>
                <div className={styles.footer}>

                </div>
            </div>
        )
    }

    return (
        <div className={styles.mainWrapper}>
            <AddFlatDiscount
                isFlatDiscountOpen={isFlatDiscountOpen}
                flatDiscountBoxController={flatDiscountBoxController}

                flatDiscountBy={flatDiscountBy}
                toggleDiscountBy={toggleDiscountBy}

                netPayableAmount={netPayableAmount}

                flatDiscount={flatDiscount}
                flatDiscountPercentage={flatDiscountPercentage}
                updateFlatDiscount={updateFlatDiscount}
                updateFlatDiscountPercentage={updateFlatDiscountPercentage}

                totalServiceDiscountedAmount={totalServiceDiscountedAmount}
            />
            <CollectPayment
                isCollecting={isCollecting}
                abortCollection={abortCollection}
                netPayableAmount={netPayableAmount}
                sessionIdentifier={sessionIdentifier}
                clientStoreRelationIdentifier={clientStoreRelationIdentifier}

                flatDiscount={flatDiscount}
                flatDiscountPercentage={flatDiscountPercentage}

                totalServiceAmount={totalServiceAmount}
                totalServiceDiscountedAmount={totalServiceDiscountedAmount}
            />
            <div className={styles.header}>
                <div className={styles.totalSavingLine}>
                    <div className={styles.tslIcon}>
                        <FaTag color="red" />
                    </div>
                    <div className={styles.tsLv}>
                        <div>
                            {/* Your total savings ({((1 - totalServiceDiscountedAmount / totalServiceAmount) * 100).toFixed(1)}%) */}
                            Your total savings ({((parseFloat(netSavings) / totalServiceAmount) * 100).toFixed(1)}%)
                        </div>
                        <div>
                            &#8377;{netSavings}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                <div className={styles.cartBody}>
                    {cartItems.map((item, index) => (
                        <EachItemInCart
                            item={item}
                            key={index}
                            getCartItems={getCartItems}
                        />
                    ))}
                </div>

                <div className={styles.calBody}>
                    <div className={styles.servicesTotal}>
                        <div>
                            Services Total
                        </div>
                        <div>
                            &#8377;{totalServiceAmount}
                        </div>
                    </div>
                    <div className={styles.allDiscountsWrapper}>
                        <div className={styles.eachDiscountRow}>
                            <div>
                                Services Discount
                            </div>
                            <div>
                                &#8377;{(totalServiceAmount - totalServiceDiscountedAmount).toFixed(1)}/-
                            </div>
                        </div>
                        <div className={styles.eachDiscountRow}>
                            <div>
                                Subscription Discount
                            </div>
                            <div>
                                &#8377;0.0/-
                            </div>
                        </div>
                        <div className={styles.eachDiscountRow}>
                            <div>
                                Flat Discount ({flatDiscountPercentage}%)
                            </div>
                            <div>
                                &#8377;{parseFloat(flatDiscount).toFixed(1)}/-
                            </div>
                        </div>
                        <div className={styles.totalDiscountRow}>
                            <div>Total Discount</div>
                            <div>&#8377;{netSavings}</div>
                        </div>
                    </div>
                    <div className={styles.grandTotalRow}>
                        <div>
                            Grand Total
                        </div>
                        <div>
                            {fetchingCartItems === true ?
                                <span>
                                    &#8377;--,---/-
                                </span>
                                :
                                <span>
                                    &#8377;{netPayableAmount}/-
                                </span>
                            }
                        </div>
                    </div>
                </div>

                <div className={styles.addFlatDiscount}>
                    <button onClick={() => setIsFlatDiscountOpen(true)} >Add Flat Discount</button>
                </div>

                <div className={styles.clientWrapper}>
                    <AddNewClient
                        isOpen={isUserFormOpen}
                        closeModal={closeModal}
                        phone={phoneNumber.otp}
                        assignClient={assignClient}
                    />
                    <ChooseClient
                        isOpen={chooseClientOpen}
                        abortClientSelection={abortClientSelection}
                        assignClient={assignClient}
                        userFormToggle={userFormToggle}
                    />
                    <div className={styles.clientDetailsContainer}
                        onClick={() => setChooseClientOpen(true)}
                    >
                        <div className={styles.cdcUpperLayer}>
                            <div>
                                Client Details
                            </div>
                            <div>
                                Change
                            </div>
                        </div>
                        {clientStoreRelationIdentifier !== '' ?
                            <div className={styles.clientName}>
                                {clientStoreRelationIdentifier.client_name}, {clientStoreRelationIdentifier.mobile}
                            </div>
                            :
                            <div>
                                Hi, 99XX99XX00
                            </div>
                        }
                    </div>
                </div>

            </div>
            <div className={styles.footer}>
                <div className={styles.collectPaymentWrapper}>
                    <div>
                        &#8377;{parseFloat(netPayableAmount).toFixed(1)}
                    </div>
                    <div>
                        {clientStoreRelationIdentifier !== '' ?
                            <>
                                {clientStoreRelationIdentifier.clientStoreRelationIdentifier !== '' ?
                                    <button className={styles.collectPaymentBtn}
                                        onClick={() => setIsCollecting(true)}
                                    >
                                        Collect Payment
                                    </button>
                                    :
                                    <button className={styles.collectPaymentBtn}
                                        onClick={() => alert('Something went wrong, kindly refresh the page')}
                                    >
                                        Collect Payment
                                    </button>
                                }
                            </>
                            :
                            <button className={styles.collectPaymentBtn}
                                onClick={() => setChooseClientOpen(true)}
                            >
                                Collect Payment
                            </button>
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Cart