import React, { useContext, useEffect, useState } from 'react'
import { MdCalendarToday, MdFilterAlt, MdRefresh, MdSort } from 'react-icons/md'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../Users/AllVisitsWrapper.module.css'
import EachVisitWrapper from './EachVisitWrapper'

const AllVisitsWrapper = props => {
    const authCtx = useContext(AuthContext)
    const clientStoreRelationIdentifier = props.clientStoreRelationIdentifier
    const [allTransactions, setAllTransactions] = useState([])

    const getAllClientVisits = async () => {
        const getAllClientVisitsResponse = await fetch(BASE_URL_API + "getAllClientVisits",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!getAllClientVisitsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientVisitsRespo = await getAllClientVisitsResponse.json();
            if (getAllClientVisitsRespo.status === 'success') {
                setAllTransactions(getAllClientVisitsRespo.response)
            } else {
                if (getAllClientVisitsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setAllTransactions([])
                }
            }
        }

    }

    console.log(allTransactions)

    useEffect(() => {
        if(clientStoreRelationIdentifier!==undefined) {
            getAllClientVisits()
        }
    }, [clientStoreRelationIdentifier])
    

    return (
        <div>
            <div className={styles.rightHeader}>
                <div className={styles.rhTitle}>
                    All Visits
                </div>
                {/* <div className={styles.rhActions}>
                    <button className={styles.eachActionBtn}>
                        <MdCalendarToday />
                    </button>
                    <button className={styles.eachActionBtn}>
                        <MdSort size={18} />
                    </button>
                    <button className={styles.eachActionBtn}>
                        <MdFilterAlt size={18} />
                    </button>
                    <button className={styles.eachActionBtn}>
                        <MdRefresh size={18} />
                    </button>
                </div> */}

            </div>
            <div className={styles.rightBody}>
                <div>
                    {allTransactions.map((item, index) => (
                        <EachVisitWrapper 
                            item={item}
                            key={index}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AllVisitsWrapper