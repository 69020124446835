import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from '../../Styles/Components/Services/AddNewService.module.css'

const AddNewService = props => {
    const authCtx = useContext(AuthContext)
    const { storeIdentifier, getServices, categoryIdentifier, categoryName, activeSection } = props
    const [showAddServiceForm, setShowAddServiceForm] = useState(false)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [serviceName, setServiceName] = useState('')
    const [basePrice, setBasePrice] = useState('');
    const [serviceTime, setServiceTime] = useState(10);

    const updateBasePriceHandler = (k) => {
        setBasePrice(k);
    }

    const addServiceHandler = async () => {
        console.log(storeIdentifier, activeSection, serviceName, categoryIdentifier, categoryName, activeSection, basePrice, serviceTime);
        if (serviceName != '') {
            if (basePrice != '') {
                if (serviceTime != '') {
                    const addANewServiceResponse = await fetch(BASE_URL_API + "addANewService",
                        {
                            method: "POST",
                            body: JSON.stringify({
                                token: authCtx.token,
                                storeIdentifier: storeIdentifier,
                                storeName: activeStore.storeName,
                                serviceName: serviceName,
                                categoryIdentifier: categoryIdentifier,
                                categoryName: categoryName,
                                // genderIdentifier: genderIdentifier,
                                basePrice: basePrice,
                                serviceTime: serviceTime,
                                sectionIdentifier: activeSection
                            })
                        });

                    if (!addANewServiceResponse.ok) {
                        console.log("Something went wrong : Server Error")
                    } else {
                        const addANewServiceRespo = await addANewServiceResponse.json();
                        if (addANewServiceRespo.status === "success") {
                            getServices();
                            setShowAddServiceForm(false)
                        } else {
                            if(addANewServiceRespo?.message==="tokenExpired") {
                                authCtx.logout()
                            } 

                        }
                        console.log(addANewServiceRespo);
                    }
                } else {
                    alert("Please select a valid Time")
                }
            } else {
                alert("Please enter a valid price...");
            }
        } else {
            alert("Please add service name...")
        }
    }


    
    return (
        <div className={styles.mainContainer}>
            {showAddServiceForm == false ?
                <button className={styles.addANewServiceBtn}
                    onClick={() => setShowAddServiceForm(true)}
                >
                    Add A New Service
                </button>
                :
                <div className={styles.sampleServicesContainer}>
                    <div className={styles.eachSampleService}>
                        <div className={styles.essTitle}>
                            <input placeholder="Service Name..."
                                onChange={(e) => setServiceName(e.target.value)}
                                value={serviceName}
                            />
                        </div>
                        <div className={styles.essDuration}>
                            <select>
                                <option value="">Time</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="25">25</option>
                                <option value="30">30</option>
                                <option value="35">35</option>
                                <option value="40">40</option>
                                <option value="45">45</option>
                                <option value="50">50</option>
                                <option value="55">55</option>
                                <option value="60">60</option>
                                <option value="65">65</option>
                                <option value="70">70</option>
                                <option value="75">75</option>
                                <option value="80">80</option>
                                <option value="85">85</option>
                                <option value="90">90</option>
                                <option value="95">95</option>
                                <option value="120">120</option>
                                <option value="125">120</option>
                                <option value="150">150</option>
                                <option value="165">165</option>
                                <option value="180">180</option>
                                <option value="195">195</option>
                                <option value="210">210</option>
                                <option value="245">245</option>
                            </select>
                        </div>
                        <div className={styles.essPrice}>
                            <input placeholder="Price"
                                onChange={(e) => updateBasePriceHandler(e.target.value)}
                                value={basePrice}
                            />
                        </div>
                        <div className={styles.essBtn}>
                            <button onClick={() => addServiceHandler()} >Add +</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AddNewService