import React, { useContext, useEffect, useState } from 'react'
import styles from './StoreEmployees.module.css'
import StoresHeader from '../../Components/Stores/StoresHeader'
import AddNewStaff from '../../Components/StaffManagement/AddNewStaff'
import { toast, ToastContainer } from 'react-toastify'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import { Link } from 'react-router-dom'


const StoreEmployees = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = JSON.parse(asCtx?.activeStore)

    const [allEmployee, setAllEmployee] = useState([{
        name: 'Loading...',
        email: 'Loading...',
        mobile: 'Loading...',
        role: 'Loading...'
    }])
    const [addNewOpen, setAddNewOpen] = useState(false)
    const closeAddNewHandler = () => {
        setAddNewOpen(false)
    }

    const postCreationHandler = () => {
        toast('Succesfully created!')
        setAddNewOpen(false)
        getWorkersData()
    }


    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx?.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setAllEmployee(getWorkersDataRespo.response)
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setAllEmployee([])
                }
            }
            console.log(getWorkersDataRespo)
        }
    }

    useEffect(() => {
        if (activeStore !== null) {
            getWorkersData()
        } else {
            console.log(`no active store selected!`)
        }
    }, [activeStore?.storeIdentifier])

    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <StoresHeader />
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.mainHeader}>
                            Please select a store!
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className={styles.mainWrapper}>
            <StoresHeader />
            <ToastContainer />
            <AddNewStaff isOpen={addNewOpen} onClose={closeAddNewHandler} onSuccess={postCreationHandler} />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainHeader}>
                        <div className={styles.title}>
                            Store Employees / CRM Users
                        </div>
                        <div className={styles.addNew}>
                            <button onClick={() => setAddNewOpen(true)} >Add A New Employee</button>
                        </div>
                    </div>
                    <div className={styles.mainBody}>
                        <div className={styles.tableWrapper}>
                            <div className={styles.tableHeader}>
                                <div className={styles.name} >Name</div>
                                <div className={styles.email} >E-mail</div>
                                <div className={styles.phone} >Phone</div>
                                <div className={styles.role} >Role</div>
                                <div className={styles.actions} >Actions</div>
                            </div>
                            <div className={styles.tableBody}>
                                {allEmployee?.map((item, index) => (
                                    <div key={index} className={styles.eachRowWrapper}>
                                        <div className={styles.name}>{item?.name}</div>
                                        <div className={styles.email}>{item?.email}</div>
                                        <div className={styles.phone}>{item?.mobile}</div>
                                        <div className={styles.role}>{item?.role}</div>
                                        <div className={styles.actions}>
                                            <Link to={`/store/employee/${item?.id}`}>Manage Employee</Link>
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default StoreEmployees