import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../store/ActiveStoreContext'
import AuthContext from '../../store/auth-context'
import styles from './PriceListSettings.module.css'

const PriceListSettings = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const vendorIdentifier = JSON.parse(authCtx?.loginData)?.partnersData?.vendorIdentifier

    const [toggleAddingSection, setToggleAddingSection] = useState(false)
    const [section, setSection] = useState('')
    const [allSections, setAllSections] = useState([])

    const getAllServicesSection = async () => {
        const getAllServicesSectionResponse = await fetch(BASE_URL_API + "getAllServicesSection",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllServicesSectionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllServicesSectionRespo = await getAllServicesSectionResponse.json()
            if (getAllServicesSectionRespo.status === "success") {
                setAllSections(getAllServicesSectionRespo?.response)
            } else {
                if (getAllServicesSectionRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllServicesSectionRespo)
        }
    }

    const createASection = async () => {
        const createASectionResponse = await fetch(BASE_URL_API + "createAServicesSection",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    vendorIdentifier : vendorIdentifier,
                    section : section
                })
            });

        if (!createASectionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createASectionRespo = await createASectionResponse.json()
            if (createASectionRespo.status === "success") {
                getAllServicesSection()
            } else {
                if (createASectionRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createASectionRespo)
        }
    }



    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    }


    return (
        <div>
            PriceListSettings
            <div>
                <button onClick={() => getAllServicesSection()} >Get Sections</button>
                <button onClick={() => setToggleAddingSection(true)} >Add Section</button>
            </div>
            <div>
                All Sections
                <div>
                    {allSections?.map((item, index) => (
                        <div key={index}>{item?.section}</div>
                    ))}
                </div>
            </div>
            <div>
                {toggleAddingSection === true &&
                    <div className={styles.addField}>
                        <input placeholder="Please enter the section title..."
                            onChange={(e) => setSection(e.target.value)}
                        />
                        <button onClick={() => createASection()}>Submit</button>
                    </div>
                }


            </div>

        </div>
    )
}

export default PriceListSettings