import React, { useContext, useEffect, useState } from 'react';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from './CollectionReport.module.css';
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf';
import { BiCalendar } from 'react-icons/bi';
import DateRangeSelector from '../../Components/UI/DateRangeSelector';
import { convertToAmPm } from '../../Helper/calculations';
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const CollectionReport = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return (asCtx?.activeStore && asCtx.activeStore.trim() !== "")
                ? JSON.parse(asCtx.activeStore)
                : null;
        } catch {
            return null;
        }
    })();
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }
    const [includeExpenses, setIncludeExpenses] = useState(false)
    const { toPDF, targetRef } = usePDF({ filename: `sd.pdf` });
    const [collectionReport, setCollectionReport] = useState(null)
    const options = {
        resolution: Resolution.HIGH,
        // resolution: Resolution.NORMAL,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            // format: 'letter',
            // default is 'portrait'
            // orientation: 'landscape',
        }

    };

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        // contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.width = '11.5in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };



    const getCollectionReport = async () => {
        const getCollectionReportResponse = await fetch(BASE_URL_API + "getCollectionReport",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getCollectionReportResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCollectionReportRespo = await getCollectionReportResponse.json()
            if (getCollectionReportRespo.status === "success") {
                setCollectionReport(getCollectionReportRespo?.response)
            } else {
                if (getCollectionReportRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setCollectionReport(null)
                }
            }
            console.log(getCollectionReportRespo)
        }
    }

    useEffect(() => {
        getCollectionReport()
    }, [dates?.startDate, dates?.endDate])




    if (activeStore === null) {
        return (
            <div>
                Please select a store first!
            </div>
        )
    } else {
        return (
            <div className={styles.masterContainer}>
                <div className={styles.crTitle} >
                    <div>
                        <button className={styles.downloadPdfBtn} onClick={() => downloadPdfHandler()} >Download PDF Report</button>
                    </div>
                    <div className={styles.toggleBtn}>
                        <button onClick={() => setIncludeExpenses(!includeExpenses)} className={styles.includeExpenses} >
                            Include Expenses {includeExpenses === true ? <FaToggleOn size={24} /> : <FaToggleOff size={24} />}
                        </button>
                    </div>
                    <div className={styles.dateWrapper}>
                        <div className={styles.dateRangeSelectorWrapperBtn}
                            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                        >
                            {dates.startDate === dates.endDate ?
                                <div>
                                    {dates.startDate} &nbsp;
                                </div>
                                :
                                <div>
                                    From {dates.startDate} to {dates.endDate} &nbsp;
                                </div>
                            }

                            <BiCalendar />
                        </div>
                        <DateRangeSelector
                            isOpen={isDatePickerOpen}
                            closeModal={closeModal}
                            changeDateHandler={changeDateHandler}
                        />

                    </div>
                </div>
                <div className={styles.mainWrapper} id="content-id">
                    <header className={styles.header} >
                        <h1>Collection Report</h1>
                        <p>{activeStore?.storeName}</p>
                        <p>{activeStore?.storeAddress}</p>
                        <p>{Date()}</p>
                    </header>

                    <section className={styles.patientDetails}>
                        <h2>Patient Collection Details</h2>
                        <div className={styles.detailsRow}>
                            <span>BILL No</span>
                            <span>DATE</span>
                            <span>PAYMENT TIME</span>
                            <span>PATIENT NAME</span>
                            <span>MOBILE</span>
                            <span>AMOUNT RECEIVED</span>
                            <span>VIA CARD</span>
                            <span>VIA QR</span>
                            <span>VIA CASH</span>
                            <span>REF. BY</span>
                        </div>
                        {collectionReport?.allTransactions?.map((item, index) => (
                            <div>
                                <div className={styles.detailsRow} key={index}>
                                    <span>S/24-25/{item?.ID}</span>
                                    <span>{item?.payStatusLastUpdatedAt?.substr(0, 10)}</span>
                                    <span>{convertToAmPm(item?.payStatusLastUpdatedAt?.substr(11, 8))}</span>
                                    <span>{item?.client_name}</span>
                                    <span>{item?.mobile}</span>
                                    <span>₹{item?.amountToBeCollected}</span>
                                    <span>₹{item?.collectionViaCard}</span>
                                    <span>₹{item?.collectionViaQR}</span>
                                    <span>₹{item?.collectionViaCash}</span>
                                    <span>{item?.referredByName}</span>
                                </div>
                                <div className={styles.cartItemWrapper}>
                                    {item?.allItemsEachBooking?.map((itemCart, indexCart) => (
                                        <div key={indexCart} className={styles.eachCartItem}>
                                            {/* {JSON.stringify(itemCart)} */}
                                            <span className={styles.serviceName}>{itemCart?.serviceName}</span>
                                            <span className={styles.categoryName}>{itemCart?.categoryName}</span>
                                            <span>{itemCart?.quantity}</span>
                                            <span>{itemCart?.totalDiscPrice}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </section>

                    <section className={styles.summarySection}>
                        <div className={styles.summaryRow}>
                            <span>Total Sales :</span>
                            <span>₹{isNaN(parseFloat(collectionReport?.totalCollection)) ? '0.00' : parseFloat(collectionReport?.totalCollection).toFixed(2)}</span>
                        </div>
                        <div className={styles.summaryRow}>
                            <span>Total Normal Collection:</span>
                            <span>₹{parseFloat(parseFloat(collectionReport?.collectionViaCard || 0) + parseFloat(collectionReport?.collectionViaCash || 0) + parseFloat(collectionReport?.collectionViaQr || 0)).toFixed(2)}</span>
                        </div>
                        <div className={styles.summaryRow}>
                            <span>Total Older Collection:</span>
                            <span>₹{parseFloat(collectionReport?.olderPayTotalAmount || 0).toFixed(2)} </span>
                        </div>
                        <div className={styles.summaryRow}>
                            <span>Total Refund:</span>
                            <span>0.00</span>
                        </div>
                        {includeExpenses === true &&
                            <div className={styles.summaryRow}>
                                <span>Total Expenses:</span>
                                <span className={styles.dangerTxt}>
                                    {parseFloat(collectionReport?.totalExpense || 0) < 0 ? '-' : ''}
                                    ₹{Math.abs(parseFloat(collectionReport?.totalExpense || 0)).toFixed(2)}
                                </span>
                            </div>
                        }
                        {includeExpenses === true ?
                            <div className={`${styles.summaryRow} ${styles.borderTop}`}>
                                <span>Total Amount Collection After Expenses:</span>
                                <span>
                                    ₹{
                                        (() => {
                                            const tnc = parseFloat(
                                                parseFloat(collectionReport?.collectionViaCard || 0) +
                                                parseFloat(collectionReport?.collectionViaCash || 0) +
                                                parseFloat(collectionReport?.collectionViaQR || 0)
                                            );

                                            const topc = parseFloat(collectionReport?.olderPayTotalAmount || 0);
                                            const te = parseFloat(collectionReport?.totalExpense || 0).toFixed(2);
                                            const tfc = tnc + topc - te;

                                            return tfc.toFixed(2); // Ensure the final result is displayed properly
                                        })()
                                    }
                                </span>
                            </div>
                            :
                            <div className={`${styles.summaryRow} ${styles.borderTop}`}>
                                <span>Total Amount Collection :</span>
                                <span>
                                    ₹{
                                        (() => {
                                            const tnc = parseFloat(
                                                parseFloat(collectionReport?.collectionViaCard || 0) +
                                                parseFloat(collectionReport?.collectionViaCash || 0) +
                                                parseFloat(collectionReport?.collectionViaQr || 0)
                                            );

                                            const topc = parseFloat(collectionReport?.olderPayTotalAmount || 0);
                                            const tfc = tnc + topc;

                                            return tfc.toFixed(2); // Ensure the final result is displayed properly
                                        })()
                                    }
                                </span>
                            </div>
                        }


                    </section>

                    <div className={styles.tableWrapper}>
                        <div className={styles.leftTableWrapper}>
                            <div className={`${styles.eachRowWrapper} ${styles.erwHeader}`}>
                                <div>Mode of Collection</div>
                                <div>Amount(Older Payment)</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Card</div>
                                <div>₹{parseFloat(collectionReport?.olderPayCollectionViaCard || 0).toFixed(2)}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Cash</div>
                                <div>₹{parseFloat(collectionReport?.olderPayCollectionViaCash || 0).toFixed(2)}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>QR</div>
                                <div>₹{parseFloat(collectionReport?.olderPayCollectionViaQr || 0).toFixed(2)}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly} ${styles.eachTableTotal}`}>
                                <div>Total</div>
                                <div>₹{parseFloat(collectionReport?.olderPayTotalAmount || 0).toFixed(2)}</div>
                            </div>

                        </div>
                        <div className={styles.leftTableWrapper}>
                            <div className={`${styles.eachRowWrapper} ${styles.erwHeader}`}>
                                <div>Mode of Collection</div>
                                <div>Amount (Current Payment)</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Card</div>
                                <div>₹{collectionReport?.collectionViaCard}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Cash</div>
                                <div>₹{collectionReport?.collectionViaCash}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>QR</div>
                                <div>₹{collectionReport?.collectionViaQr}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly} ${styles.eachTableTotal}`}>
                                <div>Total</div>
                                {/* <div>₹{collectionReport?.totalCollection}</div> */}
                                <div>₹{parseFloat(collectionReport?.collectionViaCard || 0) + parseFloat(collectionReport?.collectionViaCash || 0) + parseFloat(collectionReport?.collectionViaQr || 0)} </div>
                            </div>
                        </div>

                        <div className={styles.rightTableWrapper}>
                            <div className={`${styles.eachRowWrapper} ${styles.erwHeader}`}>
                                <div>Total Collection (Older + Current)</div>
                                {/* <div>₹{(parseFloat(collectionReport?.olderPayTotalAmount || 0) + parseFloat(collectionReport?.totalCollection || 0)).toFixed(2)}</div> */}
                                <div>₹{
                                    (() => {
                                        const tnc = parseFloat(
                                            parseFloat(collectionReport?.collectionViaCard || 0) +
                                            parseFloat(collectionReport?.collectionViaCash || 0) +
                                            parseFloat(collectionReport?.collectionViaQr || 0)
                                        );

                                        const topc = parseFloat(collectionReport?.olderPayTotalAmount || 0);
                                        const tfc = tnc + topc;

                                        return tfc.toFixed(2); // Ensure the final result is displayed properly
                                    })()
                                }</div>
                            </div>
                            {includeExpenses === true ?
                                <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                    <div>Total Expenses</div>
                                    <div>₹{(parseFloat(collectionReport?.totalExpense || 0)).toFixed(2)}</div>
                                </div>
                                :
                                <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                    <div>-</div>
                                    <div>-</div>
                                </div>
                            }
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Total Bills</div>
                                <div>{collectionReport?.totalTransactions}</div>
                            </div>
                            <div className={`${styles.eachRowWrapper} ${styles.erwOnly}`}>
                                <div>Net Cash Collection</div>
                                <div>
                                    {
                                        ((parseFloat(collectionReport?.collectionViaCash) || 0) +
                                            (parseFloat(collectionReport?.olderPayCollectionViaCash) || 0) -
                                            (parseFloat(collectionReport?.totalExpenseViaCash) || 0)
                                        ) < 0 ? '-' : ''
                                    } ₹
                                    {
                                        Math.abs(
                                            (parseFloat(collectionReport?.collectionViaCash) || 0) +
                                            (parseFloat(collectionReport?.olderPayCollectionViaCash) || 0) -
                                            (parseFloat(collectionReport?.totalExpenseViaCash) || 0)
                                        ).toFixed(2)
                                    }
                                </div>


                            </div>
                            <div className={`${styles.eachRowWrapper}  ${styles.netCollection}`}>
                                <div>Net Collection</div>
                                <div
                                    className={
                                        (parseFloat(collectionReport?.totalCollection || 0) - parseFloat(collectionReport?.totalExpense || 0)) >= 0
                                            ? styles.positive
                                            : styles.negative
                                    }
                                >
                                    {
                                        (parseFloat(collectionReport?.totalCollection || 0) - parseFloat(collectionReport?.totalExpense || 0)) < 0
                                            ? '-'
                                            : ''
                                    }
                                    {includeExpenses === true ?
                                        <span>
                                            ₹{
                                                (() => {
                                                    const tnc = parseFloat(
                                                        parseFloat(collectionReport?.collectionViaCard || 0) +
                                                        parseFloat(collectionReport?.collectionViaCash || 0) +
                                                        parseFloat(collectionReport?.collectionViaQr || 0)
                                                    );

                                                    const topc = parseFloat(collectionReport?.olderPayTotalAmount || 0);
                                                    const te = parseFloat(collectionReport?.totalExpense || 0).toFixed(2);
                                                    const tfc = tnc + topc - te;

                                                    return tfc.toFixed(2); // Ensure the final result is displayed properly
                                                })()
                                            }
                                        </span>
                                        :
                                        <span>
                                            ₹{
                                                (() => {
                                                    const tnc = parseFloat(
                                                        parseFloat(collectionReport?.collectionViaCard || 0) +
                                                        parseFloat(collectionReport?.collectionViaCash || 0) +
                                                        parseFloat(collectionReport?.collectionViaQr || 0)
                                                    );

                                                    const topc = parseFloat(collectionReport?.olderPayTotalAmount || 0);
                                                    const te = parseFloat(collectionReport?.totalExpense || 0).toFixed(2);
                                                    const tfc = tnc + topc;

                                                    return tfc.toFixed(2); // Ensure the final result is displayed properly
                                                })()
                                            }
                                        </span>
                                    }

                                    {/* ₹{Math.abs(parseFloat(collectionReport?.totalCollection || 0) - parseFloat(collectionReport?.totalExpense || 0)).toFixed(2)} */}
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
};

export default CollectionReport;
