import React, { useContext, useEffect, useState } from 'react'
import styles from '../AuthPages/Login.module.css'
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { sanitizeRoute } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { Link } from 'react-router-dom'


const Login = () => {
    const authCtx = useContext(AuthContext);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const enterUsernameHandler = (k) => {
        setUsername(sanitizeRoute(k));
    }
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')
    const [doRemember, setDoRemember] = useState(false)

    const loginHandler = async () => {
        if (username !== '' && password !== '') {
            setIsAuthenticating(true)
            setResponseMessage('Please wait...')
            const loginHandlerResponse = await fetch(BASE_URL_API + `auth`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        username: username,
                        password: password
                    })
                });

            if (!loginHandlerResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const loginHandlerRespo = await loginHandlerResponse.json();
                if (loginHandlerRespo.status === 'success') {
                    authCtx.login(loginHandlerRespo?.response?.token, loginHandlerRespo?.response?.authIdentifier, JSON.stringify(loginHandlerRespo?.response?.loginData));
                    console.log(loginHandlerRespo)
                } else {
                    setResponseMessage('Invalid username or password!')
                    console.log(loginHandlerRespo);
                }
            }
            setIsAuthenticating(false)
        } else {
            alert("Please enter valid details");
        }
    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#F8FAFF");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#F8FAFF";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.mainLogo}>
                        <img src="https://lokacipro.s3.ap-south-1.amazonaws.com/clients/rajeshwariayurdhama/logos/crm-logo.png" />
                    </div>
                    <div className={styles.titleHeader}>
                        <div className={styles.title}><h3>Sign In</h3></div>
                        <div className={styles.subTitle}><p>Just sign in if you have account. Enjoy your CRM!</p></div>
                    </div>
                    <div className={styles.loginSection}>
                        <div className={styles.loginResponse}>
                            {responseMessage}
                        </div>
                        <div className={styles.inputWrapper}>
                            <input placeholder="Please enter e-mail or phone"
                                value={username}
                                onChange={(e) => enterUsernameHandler(e.target.value)}
                            ></input>
                        </div>
                        <div className={styles.passwordWrapper}>
                            <div className={styles.passwordContainer}>
                                <input placeholder="Please enter password"
                                    type={isPasswordVisible === true ? "text" : "password"}
                                    autoComplete="off"
                                    autoFocus={true}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                ></input>
                                <div onClick={() => {
                                    setResponseMessage('Login Here!')
                                    setIsPasswordVisible(!isPasswordVisible)
                                }}
                                    className={styles.togglePass}
                                >
                                    {isPasswordVisible === true ?
                                        <BsEyeFill color="black" />
                                        :
                                        <BsEyeSlashFill color="black" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.forgotPassWrapper}>
                            <div className={styles.rememberWrapper} onClick={() => setDoRemember(!doRemember)}>
                                {doRemember===true ? <MdCheckBox size={18} color={`#007FFF`} /> : <MdCheckBoxOutlineBlank size={18} /> }&nbsp;Remember
                            </div>
                            <div className={styles.forgotPassContainer}>
                                <Link to={`/forget-password`} > Forgot Password?</Link>
                            </div>
                        </div>
                        <div className={styles.loginBtnWrapper}>
                            {isAuthenticating === true ?
                                <button>
                                    Authenticating...
                                </button>
                                :
                                <button onClick={() => loginHandler()}>Login</button>
                            }
                        </div>
                        <div>
                            <div className={styles.dividerLineWrapper}>
                                <div className={styles.justLine}></div>
                                <a href="https://wa.me/918762073939?text=Hi,%20I%20need%20help%20with%20login." className={styles.txtBetweenLine} >
                                    Need Help?
                                </a>
                                <div className={styles.justLine}></div>
                            </div>
                            {/* <div>
                                <div>
                                    Via Google
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login