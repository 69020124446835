import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { BASE_URL_API } from '../../References/URLs';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/AddOnsSelector.module.css';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import StaffSelector from './StaffSelector';
import { ShimmerThumbnail } from 'react-shimmer-effects';

const AddOnsSelector = (props) => {
    const [isAdding, setIsAdding] = useState(false);
    const lastServedBy = useRef();               // Ref to store across renders
    const sessionIdentifier = props.sessionIdentifier;
    const getCartItems = props.getCartItems;
    const authCtx = useContext(AuthContext);
    const workersData = props.workersData;
    let isOpen = props.isOpen;
    const abortAddToCart = props.abortAddToCart;
    const item = props.item;
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = (() => {
        try {
            return asCtx?.activeStore && asCtx.activeStore.trim() !== '' ? JSON.parse(asCtx.activeStore) : null;
        } catch {
            return null;
        }
    })();
    const [selectedAddOn, setSelectedAddOn] = useState({
        orgPrice: 0,
        disPrice: 0
    });

    const [consultedBy, setConsultedBy] = useState({
        id: 0,
        name: 'Self',
        profilePic: '',
        gender: 'Male'
    });
    const [servedBy, setServedBy] = useState();

    const consultedByHandler = (k) => {
        setConsultedBy(k);
    };

    const servedByHandler = (k) => {
        setServedBy(k);
        lastServedBy.current = k;      // Persist value in ref
    };

    // Restore the value from ref if state is undefined
    useEffect(() => {
        if (!servedBy && lastServedBy.current) {
            setServedBy(lastServedBy.current);
        }
    }, [servedBy]);

    window.onclick = event => event.target.id === "addOnSelector" ? abortAddToCart() : null			

    // Add to cart function
    const addToCartItem = useCallback(async () => {
        let bdp = parseFloat(parseFloat(item.basePrice) - parseFloat(item.basePrice * item.offerPercentage / 100));
        let tadbp = parseFloat(selectedAddOn.orgPrice);

        if (servedBy !== undefined) {
            setIsAdding(true);
            const addCartItemsResponse = await fetch(BASE_URL_API + 'addCartItems', {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier,
                    bookingIdentifier: sessionIdentifier,
                    categoryIdentifier: item.categoryIdentifier,
                    categoryName: item.categoryName,
                    serviceIdentifier: item.identifier,
                    serviceName: item.serviceName,
                    serviceTime: item.serviceTime,
                    genderIdentifier: item.genderIdentifier,
                    genderLabel: item.genderLabel,
                    basePrice: item.basePrice,
                    baseDiscountedPrice: bdp,
                    totalAddOnsBasePrice: tadbp,
                    totalBasePrice: parseFloat(parseFloat(item.basePrice) + parseFloat(selectedAddOn.orgPrice)).toFixed(1),
                    totalDiscPrice: parseFloat(parseFloat(bdp) + parseFloat(selectedAddOn.disPrice)),
                    hsnCode : item?.hsnCode,
                    batchNo : item?.batchNo,
                    addOnCategory: selectedAddOn.addOnCategory,
                    addOnType: selectedAddOn.addOnType,
                    addOnOrgPrice: selectedAddOn.orgPrice,
                    addOnDisPrice: selectedAddOn.disPrice,
                    addOnLabel: selectedAddOn?.label !== undefined ? selectedAddOn.label : 'Regular',
                    consultedByIdentifier: consultedBy.id,
                    consultedByName: consultedBy.name,
                    consultedByGender: consultedBy.gender,
                    servedByIdentifier: servedBy.id,
                    servedByName: servedBy.name,
                    servedByGender: servedBy.gender
                })
            });

            if (!addCartItemsResponse.ok) {
                console.error('Something went wrong: Server Error');
            } else {
                const addCartItemsRespo = await addCartItemsResponse.json();
                if (addCartItemsRespo.status === 'success') {
                    getCartItems();
                    abortAddToCart();
                    document.getElementById('priceListSearchBar').select();
                } else if (addCartItemsRespo.message === 'tokenExpired') {
                    authCtx.logout();
                } else {
                    console.error(addCartItemsRespo);
                }
            }
            setIsAdding(false);
        } else {
            alert('Please select the staff provided services!');
        }
    }, [authCtx.token, activeStore, sessionIdentifier, item, selectedAddOn, consultedBy, servedBy, getCartItems, abortAddToCart]);

    // Debounce function to limit function triggering frequency
    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    useEffect(() => {
        if(isOpen) {
            const handleKeyDown = debounce((event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    addToCartItem();
                }
                if (event.key === 'Escape') {
                    abortAddToCart();
                }
            }, 300);
            
            document.addEventListener('keydown', handleKeyDown);
            
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [addToCartItem, abortAddToCart]);

    useEffect(() => {
        setSelectedAddOn({ orgPrice: 0, disPrice: 0 });
        setConsultedBy({ id: 0, name: 'Self', profilePic: '', gender: 'Male' });
    }, [isOpen]);

    const calculateTotalPrice = () => {
        let tOrgPrice = parseFloat(item.basePrice) + parseFloat(selectedAddOn.orgPrice);
        let tDisPrice = parseFloat(item.basePrice - (item.basePrice * item.offerPercentage / 100)) + parseFloat(selectedAddOn.disPrice);

        return { top: parseFloat(tOrgPrice).toFixed(0), tdp: parseFloat(tDisPrice).toFixed(0) };
    };

    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="addOnSelector" style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}>
                    <div className={styles.mainSelectorBox}>
                        <div className={styles.mainSelectorHeader}>
                            Select the product brand for {item.categoryName}
                        </div>
                        <div className={styles.mainSelectorBody}>
                            {item.addOns?.sort((a, b) => a.orgPrice - b.orgPrice).map((itemAddOns, indexAddOns) => (
                                <div key={itemAddOns.labelId + '0'} className={styles.eachAddOnWrapper} onClick={() => setSelectedAddOn(itemAddOns)}>
                                    <div className={styles.leftSection}>{itemAddOns.label}</div>
                                    <div className={styles.centerSection}>
                                        <div className={styles.addOnOrgPrice}>&#8377;{itemAddOns.orgPrice}</div>
                                        <div className={styles.addOnDisPrice}>&#8377;{itemAddOns.disPrice}</div>
                                    </div>
                                    <div className={styles.rightSection}>
                                        {itemAddOns === selectedAddOn ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
                                    </div>
                                </div>
                            ))}

                            <StaffSelector selectingFor="Consulted By" workersData={workersData} selectedStaff={consultedBy} consultedByHandler={consultedByHandler} />
                            <StaffSelector selectingFor="Served By" workersData={workersData} selectedStaff={servedBy} servedByHandler={servedByHandler} />
                        </div>
                        <div className={styles.mainSelectorFooter}>
                            <div>
                                {selectedAddOn !== null && (
                                    <div className={styles.pricesWrapper}>
                                        <div className={styles.top}>&#8377;{calculateTotalPrice().top}</div>
                                        <div className={styles.tdp}>&#8377;{calculateTotalPrice().tdp}</div>
                                    </div>
                                )}
                            </div>
                            <div className={styles.addToCartBtnWrapper}>
                                {isAdding === true ? <ShimmerThumbnail height={32} width={140} rounded className={styles.noBottomMargin} /> : <button onClick={addToCartItem}>Add To Cart</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default AddOnsSelector;
