import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Routes, useParams } from 'react-router-dom'; // Use Link, Routes, and Route
import styles from './AllInvoices.module.css';
import AuthContext from '../../store/auth-context';
import ActiveStoreContext from '../../store/ActiveStoreContext';
import { ToastContainer } from 'react-toastify';
import ViewInvoice from '../../Components/Invoices/ViewInvoice';
import { BASE_URL_API } from '../../References/URLs';

const AllInvoices = () => {
    const authCtx = useContext(AuthContext);
    const asCtx = useContext(ActiveStoreContext);
    const activeStore = JSON.parse(asCtx?.activeStore);
    const { invoiceIdentifier } = useParams();

    const [data, setData] = useState([])

    const [invoices, setInvoices] = useState([
        { invoiceIdentifier: '34jklsdh', invoiceKey: 'Invoice 1' },
        { invoiceIdentifier: '12abc456', invoiceKey: 'Invoice 2' },
    ]);

    const getInvoices = async () => {
        const getInvoicesResponse = await fetch(BASE_URL_API + `getInvoices`,
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getInvoicesResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getInvoicesRespo = await getInvoicesResponse.json()
            if (getInvoicesRespo.status === "success") {
                setInvoices(getInvoicesRespo?.response)
                setData(getInvoicesRespo?.response)
            } else {
                if (getInvoicesRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setInvoices([])
                }
            }
            console.log(getInvoicesRespo)
        }
    }

    useEffect(() => {
        getInvoices()
    }, [activeStore?.storeIdentifier])

    const downloadCSV = () => {
        const csvRows = [];

        // Get the headers
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        // Loop over the rows
        data.forEach(row => {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        });

        // Create a Blob from the CSV rows and download it
        const blob = new Blob([csvRows.join('\n')], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `invoice_data_${Date.now()}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };



    if (activeStore === null) {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.mainContent}>
                        <div className={styles.upperHeader}>
                            Please select a store
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {/* Render ViewInvoice if an invoiceIdentifier is present */}
            {invoiceIdentifier && <ViewInvoice invoiceIdentifier={invoiceIdentifier} />}

            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <div className={styles.upperHeader}>
                        <div>
                            <div className={styles.hiname}>
                                {activeStore?.storeName} CRM
                            </div>
                            <div className={styles.proName}>
                                {activeStore?.storeAddress}
                            </div>
                        </div>
                        <div className={styles.downloadAction}>
                            {/* <Link to={`/add-clients`} className={styles.addClient}>
                                + Add Client
                            </Link> */}
                            <button onClick={() => downloadCSV()} className={styles.downloadBtn}  >Download In Sheet</button>
                        </div>
                    </div>

                    <div className={styles.bodyWrapper}>
                        <div className={styles.tableHeader}>
                            <div className={styles.sn}>#</div>
                            <div className={styles.invoice}>Invoice #</div>
                            <div className={styles.invoice}>Date</div>
                            <div className={styles.client}>Client Name</div>
                            <div className={styles.amount}>Amount</div>
                            <div className={styles.status}>Discount</div>
                            <div className={styles.status}>Status</div>
                            <div className={styles.action}>Action</div>
                        </div>
                        <div className={styles.tableBody}>
                            {invoices?.map((item, index) => (
                                <div className={styles.eachRow} key={index}>
                                    <div className={styles.sn}>{index + 1}</div>
                                    <div className={styles.invoice}>{item?.billNumber}</div>
                                    <div className={styles.invoice}>{item?.createdOn?.substring(0, 10)}</div>
                                    <div className={styles.client}>{item?.clientName}</div>
                                    <div className={styles.amount}>₹{item?.amountToBeCollected}</div>
                                    <div className={styles.status}>₹{parseFloat((parseFloat(item?.servicesTotalOrgPrice) || 0) - (parseFloat(item?.amountToBeCollected) || 0)).toFixed(2)}</div>
                                    <div className={styles.status}>Paid</div>
                                    <div className={styles.action}>
                                        <Link to={`/invoice/${item.invoiceKey}`}>
                                            View Invoice
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default AllInvoices;
